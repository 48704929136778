.columns {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  .one {
    grid-column: 1 / 3;
  }

  .two {
    grid-column: 2 / 4;
  }

  .three {
    grid-column: 1;
  }

  .four {
    grid-column: 3;
  }

  .five {
    grid-column: 2;
  }

  .six {
    grid-column: 3;
  }

  @media (min-width: 600px) {
    .two {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}