.component-search {
  padding: 0;
  margin: 0;
  --border-radius: var(--app-narrow-radius);
  --background: var(--ion-card-background);
  --box-shadow: none;
  --border-radius: var(--app-narrow-radius);
  padding: 0;
  width: 100%;
  min-width: 100%;


  .searchbar-input {
    font-size: 12px !important;
    line-height: 24px;
    /* padding-inline-start: 20px; */
    -webkit-padding-start: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .searchbar-clear-button {
    right: 35px;
  }

  .searchbar-search-icon {
    width: 15px;
    height: 15px;
    right: 14px;
    left: auto;
    top: 9px;
  }
}

.component {

  position: relative;
  width: 100%;

  .price-tags {
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    gap: 12px;
    display: flex;
    flex-wrap: wrap;

  }

  .component-details-outer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    z-index: 2;
    width: 100%;
    margin-top: 12px;
  }




  .component-social-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
  }




  &:not(.component-header-no-padding) {
    .page-content {
      padding-top: 8%;
    }
  }


  .component-navigation-outer {

    margin-top: 32px;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 6px;
    margin-bottom: 6px;

    &.no-margin {
      margin-top: 0;
    }

    -webkit-overflow-scrolling: touch;
    position: relative;
    touch-action: auto;
    height: 45px;
  }

  .component-navigation {
    border-bottom: 2px solid var(--ion-border-color);
    min-width: 100%;
    display: flex;
    gap: 24px;

    position: absolute;
    left: 0;

    .component-navigation-begin {}


    .component-navigation-element {
      -webkit-overflow-scrolling: touch;
      font-weight: 500;
      color: var(--ion-color-step-600);
      padding-bottom: 14px;
      padding-left: 2px;
      padding-right: 2px;
      white-space: nowrap;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: var(--ion-color-primary);
        display: none;
      }


      &.active {

        color: var(--ion-text-color);

        &:before {

          display: block;
        }
      }


      &:hover {
        color: var(--ion-text-color);
      }
    }
  }



  .component-tag-outer {
    padding-bottom: 6px;
    margin-bottom: 24px;
    /* border: 1px solid var(--ion-border-color);
    border-radius: 16px;
    padding: 12px;*/
  }

  .component-filter-outer {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;

    .component-filter-label {
      color: var(--ion-color-step-650);
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .component-filter-element-full {
      flex: 1;
    }

    .component-filter-element {

      display: flex;
      border-radius: var(--app-narrow-radius);
      background: var(--input-background);
      padding: 6px 10px;
      height: 32px;
      font-size: 13px;
      align-items: center;
      gap: 6px;
      width: 100%;

      .clear-button {
        background: transparent;
        width: 24px;
        height: 20px;
        position: relative;
        border-radius: var(--app-narrow-radius);

        ion-icon {
          top: 0;
          left: 0;
          color: var(--ion-color-danger);
          position: absolute;
          font-size: 20px;
        }

        &:hover {
          background: var(--ion-color-danger);

          ion-icon {
            color: var(--ion-color-danger-contrast);
          }
        }
      }

      ion-select {
        width: 100%;
        font-size: 13px;
        background: transparent;
        margin: 0;
        padding: 0;
        border-radius: 0;
      }

      ionic-selectable {
        font-size: 13px;
        margin: 0;
        width: 100%;
        padding: 0;
        background: transparent;
        border-radius: 0;

        .ionic-selectable-value {
          padding-top: 0;
          padding-bottom: 0;
        }

        .ionic-selectable-icon-inner {
          top: 6px;
        }
      }
    }
  }

  .is-full-width {
    .component-filters-mobile {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      row-gap: 12px;

      .component-filter-outer {
        width: auto;
        flex: auto;
        margin: 0;
        min-width: auto;
      }

      .component-filter-label {
        display: none;
      }
    }
  }


  .component-tag-filter {
    min-width: 100%;
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    row-gap: 6px;
    align-items: center;

    .component-tag-filter-begin {
      font-weight: 600;
      color: var(--ion-color-step-600);
      font-size: 12px;
      margin-right: 6px;
    }

    .component-tag-filter-element {
      -webkit-overflow-scrolling: touch;
      font-weight: 500;
      color: var(--ion-color-step-750);

      padding: 6px 10px;
      font-size: 13px;
      cursor: pointer;
      position: relative;
      border: 2px solid var(--ion-border-color);
      border-radius: var(--app-narrow-radius);


      &.active {

        color: var(--ion-color-primary);
        border: 2px solid var(--ion-color-primary);


      }


      &:hover {
        color: var(--ion-color-primary);
      }
    }
  }

  .component-single-header-outer {
    background: var(--ion-card-background);
    border-bottom: 1px solid var(--ion-border-color);

  }

  .component-single-header {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: space-between;

    .component-side-actions {
      display: flex;
      gap: 6px
    }


  }

  .space-down {
    padding-bottom: 24px;
  }

  .component-desc {
    font-size: 13px;
    color: var(--ion-color-step-650);
    margin: 0;

  }

  .component-socials {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }


}


.component-social {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  align-items: center;

  .component-social-icon {
    width: 30px;
    height: 30px;
    background-color: #fff;

    border-radius: 6px;
    overflow: hidden;

    &.official {
      background: #fff url("../assets/socials/official.svg") no-repeat;
      background-size: cover;
    }

    &.discord {
      background: #fff url("../assets/socials/discord.svg") no-repeat;
      background-size: cover;
      background-size: 120% 120%;
      background-position: center;
    }

    &.twitter {
      background: #fff url("../assets/socials/twitter.svg") no-repeat;
      background-size: cover;
    }

    &.steam {
      background: #fff url("../assets/socials/steam.svg") no-repeat;
      background-size: cover;
    }

    &.facebook {
      background: #fff url("../assets/socials/facebook.svg") no-repeat;
      background-size: cover;
    }

    &.youtube {
      background: #fff url("../assets/socials/youtube.svg") no-repeat;
      background-size: cover;
    }

    &.wikipedia {
      background: #fff url("../assets/socials/wikipedia.svg") no-repeat;
      background-size: cover;
    }

    &.twitch {
      background: #fff url("../assets/socials/twitch.svg") no-repeat;
      background-size: cover;
    }

    &.android {
      background: #fff url("../assets/socials/android.svg") no-repeat;
      background-size: cover;
    }

    &.ipad {
      background: #fff url("../assets/socials/ipad.svg") no-repeat;
      background-size: cover;
    }

    &.iphone {
      background: #fff url("../assets/socials/iphone.svg") no-repeat;
      background-size: cover;
    }

    &.reddit {
      background: #fff url("../assets/socials/reddit.svg") no-repeat;
      background-size: cover;
    }

    &.instagram {
      background: #fff url("../assets/socials/instagram.svg") no-repeat;
      background-size: cover;
    }


    &.wikia {
      background-size: cover;
    }
  }

  .component-social-text {
    font-size: 14px;
    color: var(--ion-text-color);
  }

  &:hover {

    .component-social-text {
      color: var(--ion-color-primary);
      text-decoration: underline;
    }

  }
}

.detail-info-badge {
  font-size: 13px;
  align-content: center;
  line-height: 23px;
  display: flex;
  align-items: center;
  gap: 6px;
  --padding-start: 8px;
  --padding-end: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}


.app-item-badge {
  --color: var(--ion-color-secondary-contrast);
  --background: var(--ion-color-secondary);
  font-size: 11px;
  padding: 2px 8px;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  color: var(--color);
  border-radius: var(--app-narrow-radius);
  display: inline-flex;
  align-content: center;
  display: flex;
  align-items: center;
  background: var(--background);

  ion-icon {
    font-size: 14px;
    pointer-events: none;
    color: var(--color);
  }

  &.xl {
    font-weight: 500;
    padding: 6px 10px;
    font-size: 13px;
  }
}

.component-widgets {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .component-widget {
    .component-widget-sub-title {
      padding: 0;
      font-size: 13px;
      font-weight: bold;
      color: var(--ion-color-step-600);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }

    .component-widget-sub {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 6px;
    }

    .component-widget-header {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 6px;
      margin-bottom: 12px;
    }

    .component-widget-header-inner {
      margin-top: 0;
      /* height: 40px; */
      min-height: 0;
      display: flex;
      font-size: 11px;
      padding-top: 0;

      --header-color: var(--ion-color-step-500);

      gap: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: flex;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--header-color);
      margin-bottom: 6px;

      .component-widget-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }


    }

    .component-widget-desc {
      font-size: 14px;
      margin: 0;
      padding: 12px 0px;
      color: var(--ion-color-step-600, #666666);
    }
  }
}

.component-widget-cta {
  margin-top: 12px;
  width: 100%;
}



.widget-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  padding: 12px 0;
  cursor: pointer;
  width: 100%;


  &.widget-spacer {
    border-bottom: 1px solid var(--ion-border-color);
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    span {
      color: var(--ion-color-primary);
      text-decoration: underline;
    }

    ion-icon {
      color: var(--ion-color-primary);
    }
  }

  ion-icon {
    font-size: 18px;
  }
}


.widget-image {
  position: relative;
  width: auto;
  display: block;
  overflow: hidden;
  background: var(--ion-card-background);

  ion-skeleton-text {
    margin: 0;
    padding: 0;
  }

  &.square-xs {
    height: 48px;
    width: 48px;
    --border-radius: var(--app-narrow-radius);
    border-radius: var(--app-narrow-radius);
  }

  &.avatar-xs {
    height: 35px;
    width: 35px;
    --border-radius: 50%;
    border-radius: 50%;
  }

  &.icon-menu {
    height: 45px;
    width: 45px;
    --border-radius: 50%;
    border-radius: 50%;
  }

  &.cover-xs {
    height: 70px;
    width: 55px;
    --border-radius: var(--app-narrow-radius);
    border-radius: var(--app-narrow-radius);
  }

  &:hover {
    filter: brightness(110%);
  }


  ion-img,
  .widget-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
  }

  ion-img::part(image),
  img {

    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--ion-card-background);
  }

  ion-img.unloaded::part(image),
  img.unloaded {
    opacity: 0;
    transition: opacity 0.1s;
  }

  ion-img.loaded::part(image),
  img.loaded {
    opacity: 1;
  }
}

.component-slugs {
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  color: var(--ion-color-step-700);

  &.listed {
    >* {
      position: relative;
      margin-left: 12px;
      line-height: 18px !important;

      &:before {
        content: '\2022';
        font-weight: 500;
        color: var(--ion-color-step-750);
        font-size: 13px;
        position: absolute;
        left: -14px;
        line-height: 18px;
      }
    }

    >*:first-child {
      margin-left: 0;

      &:before {
        display: none;
      }
    }
  }

}



.component-background-outer {
  position: relative;

  .page-content {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.component-background {
  width: 100%;
  max-height: 400px;
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;

  .details {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    left: 0;


    .component-header {
      margin: 0 !important
    }

    .page-content {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  border-bottom: 1px solid var(--ion-border-color);
  background: var(--ion-card-background);

  img {
    width: 100%;

  }

  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

  }

  video {
    width: 100%;
  }
}

h1.component-title {
  display: flex;
  margin: 0;
  z-index: 1;
  font-size: 24px;
  align-items: center;
  gap: 12px;

  .small {
    color: var(--ion-color-step-500);
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
  }
}

.component-header {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: space-between;
  flex-wrap: wrap;

  .component-infos {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    text-align: left;
    flex: 1;
    padding: 12px 0px;
  }
}

.component-header-outer {
  --width: 0px;
  --height: 0px;
  --extra-space-down: 0px;

  &.has-cover {
    --width: calc(205px / 1.3);
    --height: calc(275px /1.3);
    --extra-space-down: 35px;
    display: block;
    overflow: hidden;
  }

  &.has-avatar {
    --width: 160px;
    --height: 160px;
    --extra-space-down: 0;
    display: block;
    overflow: hidden;
  }

  .component-header-inner-down {



    .page-content {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }

    .component-header {
      padding-left: calc(var(--width) + 32px);
    }

    margin-bottom: var(--extra-space-down);
  }

  .component-title-outer {
    bottom: 0;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }


  .component-background-outer {
    position: relative;
  }

  .component-header-inner {


    display: flex;
    position: relative;
    bottom: 0;
    width: 100%;


    .cover-outer {
      position: relative;
      margin-right: var(--width);

      .cover {
        position: absolute;
        width: var(--width);
        top: calc(var(--height)/4.0*-1);
        height: var(--height);
        overflow: hidden;
        --border-radius: 5px;
        border-radius: 7px;
        background: var(--ion-card-background);
        border: 2px solid var(--ion-background-color);

        ion-skeleton-text {
          margin: 0;
          padding: 0;
        }

        ion-img,
        .cover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          left: 0;
          top: 0;
        }

        ion-img::part(image),
        img {

          object-fit: cover;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background: var(--ion-card-background);
        }

        ion-img.unloaded::part(image),
        img.unloaded {
          opacity: 0;
          transition: opacity 0.1s;
        }

        ion-img.loaded::part(image),
        img.loaded {
          opacity: 1;
        }
      }
    }

    .avatar-outer {
      position: relative;
      margin: 0;
      width: 100%;
      margin-bottom: calc(var(--width) / 2);

      .avatar {
        position: absolute;
        width: auto;
        top: calc(var(--width)/2*-1);
        --border-radius: 50%;
        height: var(--width);
        width: var(--width);
        left: calc(50% - var(--width) / 2);
        overflow: hidden;
        border-radius: 50%;
        background: var(--ion-card-background);
        border: 2px solid var(--ion-background-color);

        ion-skeleton-text {
          margin: 0;
          padding: 0;
        }

        ion-img,
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          left: 0;
          top: 0;
          background: var(--ion-card-background);
          text-indent: 100%;
          white-space: nowrap;
        }


        ion-img.unloaded::part(image),
        img.unloaded {
          opacity: 0;
          transition: opacity 0.1s;
        }

        ion-img.loaded::part(image),
        img.loaded {
          opacity: 1;
        }

        ion-img::part(image) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
        }

        .avatar-container {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;

          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          left: 0;
          top: 0;
        }
      }
    }
  }



  .component-header-outer {


    &.with-cover {
      margin-bottom: 40px;
    }

    .page-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .component-header-inner-down {
      padding-left: var(--size);
    }

    .component-header {}

  }
}

.viewport {
  width: 100%;
  color: white;
  overflow: hidden;

  .line {
    width: 100%;
    background: var(--ion-border-color);
    height: 1px;
  }

  .viewport-content {
    padding-top: 0;
  }

  .viewport-header {
    display: flex;
    width: 100%;
    margin-top: 12px;

    align-items: center;
    gap: 24px;

    span {
      white-space: nowrap;
    }
  }

  &-content {
    max-height: 0;
    padding: 0;
    padding-top: 24px;
    transition: all 0.35s;
  }

  // :checked
  &.active {
    .viewport-icon {
      &::after {
        transform: rotate(90deg);
      }
    }

    .viewport-content {
      max-height: 100vh;
      padding: 0;
      padding-top: 24px;
    }
  }
}

.no-padding-top {
  padding-top: 0 !important;
}

.info-status {
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 14px;
  color: var(--ion-color-step-600);
  opacity: 1;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  margin-top: 0;
  font-size: 13px;
  color: var(--ion-color-step-600);
  opacity: 1;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  &.truncate-2 {

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.like-actions {
  display: flex;
  gap: 6px;
  flex-direction: column;
}