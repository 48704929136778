/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS fonts */

@import 'theme/reset.scss';

@import 'theme/responsive.scss';
@import 'theme/tooltip.scss';
@import 'theme/grid.scss';
@import 'theme/alert.scss';
@import 'theme/stars.scss';
@import 'theme/electron.scss';
@import 'theme/wizard.scss';
@import 'theme/input.scss';
@import 'theme/teaser.scss';
@import 'theme/item.scss';
@import 'theme/badges.scss';
@import 'theme/component.scss';
@import 'theme/heros.scss';
@import 'theme/auth.scss';
@import 'theme/popover.scss';
@import 'theme/scrollbar.scss';
@import 'theme/footer.scss';
@import 'theme/modal.scss';
@import 'theme/forms.scss';
@import 'theme/table.scss';
@import 'theme/markdown.scss';
@import 'theme/emoji-outline.scss';
@import 'theme/dev-center.scss';
@import 'theme/macos.scss';
@import 'theme/button.scss';
@import 'theme/rating.scss';
@import 'theme/side-menu.scss';
@import 'theme/tabs.scss';
@import 'theme/landing.scss';
@import 'theme/header.scss';
@import 'theme/columns.scss';

.margin-down {
  margin-bottom: 24px;
  display: block;
}

.margin-down-12 {
  display: block;
  margin-bottom: 12px;
}

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 35px !important;
}

.sc-ion-textarea-md-h:not(.legacy-textarea) {
  min-height: 35px !important;
}

.editor-statusbar .lines:before {
  content: 'Lines：';
}

.editor-statusbar .counts:before {
  content: 'Count: ';
}

.CodeMirror-cursor {
  border-left: 1px solid var(--ion-text-color);
}

.CodeMirror,
.editor-toolbar {
  border: 1px solid var(--ion-border-color);
  color: var(--ion-text-color);
  background: var(--input-background);

  a {
    color: var(--ion-text-color) !important;

    &:hover {
      background: var(--ion-color-primary);
      border-color: var(--ion-color-primary);

      color: var(--ion-color-primary-contrast) !important;

    }
  }
}

html,
body,
ion-app {
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricprecision;
  text-size-adjust: 100%;
}

a {
  color: var(--ion-color-primary);

  &:hover {
    color: var(--ion-color-primary-tint);
  }

  &:focus {
    color: var(--ion-color-primary-shade);
  }

}

ion-accordion ion-item[slot='header'] {
  .ion-accordion-toggle-icon {
    padding: 0;
    margin: 0;
  }
}


ion-searchbar {
  --border-radius: var(--app-narrow-radius);
  --color: var(--ion-text-color);
}

.sc-ion-buttons-md-s ion-button:not(.button-round) {
  --border-radius: var(--app-narrow-radius);
}

.change-permission-alert {
  --min-width: 350px;
}

.swiper-horizontal>.swiper-scrollbar {
  position: inherit;
  left: 0;
  bottom: 0px;
  z-index: 50;
  height: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
  background: var(--ion-card-background);
  border-radius: var(--app-narrow-radius);

  .swiper-scrollbar-drag {
    background: var(--ion-card-background-hover);
    border-radius: var(--app-narrow-radius);
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    &:hover {
      background-color: var(--ion-color-primary);
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  top: 50%;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 20px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 20px;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-weight: bold;
  color: var(--ion-color-warning);
}

.swiper:hover {

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    color: var(--ion-color-medium);
  }
}

.horz-scroll {
  scrollbar-width: auto;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;

  z-index: 9999;
  padding: 0px;
}

ion-toolbar.toolbar-segment {
  display: block;
  overflow: auto;
}

ion-segment {
  overflow-x: auto;
  margin-bottom: 12px;
  margin-top: 12px;
}

ion-segment.md {
  overflow: visible;
  display: inline-flex;
  white-space: nowrap;
  height: auto;
  padding: 0;
  margin: 0;
  min-height: auto;
  --min-height: 0;
  white-space: nowrap;
  /* border-bottom: 1px solid var(--ion-border-color); */
  display: block;
  text-align: left;
  flex-flow: wrap;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background: var(--ion-border-color);
    height: 1px;
    bottom: 4px;
  }

  ion-segment-button {
    display: inline-block;
    --color-checked: var(--ion-text-color);
    --color: var(--ion-color-step-650);
    --ripple-color: transparent;
    --background-hover: transparent;
    --indicator-color: var(--ion-color-primary, #3880ff);
    width: auto;
    border-radius: var(--app-narrow-radius) var(--app-narrow-radius) 0px 0px;
    overflow: hidden;
    --indicator-height: 2px;
    --padding-start: 0;
    --padding-end: 0;
    margin-right: 32px;

    &:not(.segment-button-checked):hover {
      &::part(indicator) {
        opacity: 1;
      }

      --indicator-color: var(--ion-card-background-hover, #3880ff);
      --indicator-height: 2px;
    }

    ion-label {
      text-transform: none;
      margin: 0;
      font-size: 15px;
    }
  }
}


.alert-radio-inner.sc-ion-alert-md {
  background-color: var(--ion-color-primary-tint, #3880ff);
}

ion-item-divider {
  --background: var(--ion-card-background);
}

.horz-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow: hidden;
  z-index: 9999;
  cursor: pointer;
}

.horz-scroll::-webkit-scrollbar-track:vertical {
  border-right: 10px solid transparent;
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.horz-scroll::-webkit-scrollbar-thumb:vertical {
  border-right: 10px solid transparent;
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.horz-scroll::-webkit-scrollbar-track:horizontal {
  border-bottom: 10px solid transparent;
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.horz-scroll::-webkit-scrollbar-thumb:horizontal {
  border-bottom: 10px solid transparent;
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.horz-scroll::-webkit-scrollbar-corner,
.horz-scroll::-webkit-resizer {
  background: inherit;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.horz-scroll {
  &::-webkit-scrollbar-track:vertical {
    z-index: 9999;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background: var(--ion-scrollbar-color);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: var(--ion-scrollbar-color);
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    background: var(--ion-scrollbar-color);
  }
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: var(--ion-font-family);
}

a {
  text-decoration: none;
}


.backdrop-blur {
  --backdrop-opacity: 0;
}

.cdk-overlay-backdrop {
  background: var(--ion-backdrop-color, #000);
}



ion-input,
ion-button {
  font-size: 14px;
  --border-radius: var(--app-narrow-radius);

  text-transform: none;
}

ion-card-subtitle {
  font-weight: normal;
  font-size: 12px;
  word-break: break-word;
  white-space: pre-wrap;
  margin-top: 4px;
}

.line-clamp-2 {
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ion-card-title {
  display: block;
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-align: left;
  margin: 0;
  color: var(--ion-color-dark);
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 700;

  a {
    color: var(--ion-color-dark);
  }
}

a {
  &:hover {
    ion-img::part(image) {
      filter: brightness(110%);
    }
  }
}

ion-modal {
  --box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

ion-card-title {
  color: var(--ion-text-color);

  a {
    color: var(--ion-text-color);

    &:hover {
      color: var(--ion-color-primary);
    }
  }
}


.light {


  ion-card-title {
    color: var(--ion-color-dark);

    a {
      color: var(--ion-color-dark);

      &:hover {
        color: var(--ion-color-primary);
      }
    }
  }
}

ion-modal {
  ion-item {
    --border-color: rgba(var(--ion-color-light-rgb), 0.1);
  }

  .close-button {
    position: absolute;
    right: 12px;
    z-index: 11;
    top: 12px;
  }
}

ion-modal.auto-height {
  --height: auto;

  .tab-header {
    display: block;
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
    color: var(--ion-text-color);
    margin-bottom: 24px;
  }

  .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
  }

  .modal-content {
    overflow: hidden;
    overflow-y: scroll;
    display: block;
    padding: 24px 20px;
  }

  .modal-wrapper {
    width: 90vw;
  }
}

ion-modal.fullscreen {
  --width: 65% !important;
  --height: auto;
  --max-width: calc(1280px - 85px);


  .ion-page {

    max-height: none !important;
  }

}


.modal-content {
  overflow: hidden overlay !important;
  scrollbar-width: auto;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow: hidden;
  z-index: 9999;
}

.modal-content::-webkit-scrollbar-track:vertical {
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.modal-content::-webkit-scrollbar-thumb:vertical {
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.modal-content::-webkit-scrollbar-track:horizontal {
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.modal-content::-webkit-scrollbar-thumb:horizontal {
  border-radius: var(--app-narrow-radius);
  cursor: pointer;
}

.modal-content::-webkit-scrollbar-corner,
.modal-content::-webkit-resizer {
  background: inherit;
}

.modal-content:hover::-webkit-scrollbar-track:vertical {
  z-index: 9999;
}

.modal-content:hover::-webkit-scrollbar-thumb:vertical {
  background: var(--ion-scrollbar-color);
}

.modal-content:hover::-webkit-scrollbar-track:horizontal {
  background: var(--ion-scrollbar-color);
}

.modal-content:hover::-webkit-scrollbar-thumb:horizontal {
  background: var(--ion-scrollbar-color);
}

.modal-content:hover::-webkit-scrollbar-corner,
.modal-content:hover::-webkit-resizer {
  background: var(--ion-scrollbar-color);
}

ion-item {
  --background-hover: var(--ion-card-background);
  --background-hover-opacity: 1;
}

ion-title {
  font-size: 18px;
}

ion-badge {
  line-height: 140%;
  font-weight: 400;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 4px;
  --padding-end: 4px;
  font-size: 11px;
  height: auto;
  border-radius: var(--app-narrow-radius);
  text-align: center;
}

ion-button {
  text-transform: none;
}

.sc-ion-input-md-h,
.sc-ion-textarea-md-h {
  font-size: 14px;
  text-transform: none;
}

ion-icon {
  pointer-events: none;
}

ion-icon.expand-icon {
  font-size: 12px;
}

.header-md::after {
  background-image: none;
  bottom: -4px;
}

.swiper-pagination-bullet {
  background: var(--ion-color-primary) !important;
  width: 13px;
  opacity: 0.4;
  height: 13px;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
  opacity: 1.0;
}



.swiper-slide {
  height: auto;

  img {
    width: 100%;
    max-width: 100%;
  }
}

ion-card {
  box-shadow: none;
  border-radius: var(--app-narrow-radius);
  filter: none;
  --background: var(--ion-card-background);

  border: 1px solid var(--ion-border-color);

  ion-thumbnail {
    overflow: hidden;
  }

  &:hover {
    --background: var(--ion-card-background-hover);
  }
}



.edit-inline-header {
  --background: var(--ion-card-background);
  --background-hover: var(--ion-card-background-hover);
  --border-radius: var(--app-narrow-radius);
  --ripple-color: transparent;
}

.info-sub-header {
  margin: 0;

  ion-label {
    font-size: 13px;
    color: var(--ion-color-step-750);
  }
}

.side-list {
  padding-top: 0;

  .side-list-item {
    margin-top: 24px;
    width: 100%;
  }

  .side-list-item:first-child {
    margin-top: 0;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.side-list-item {
  padding-top: 0;
  display: block;
  overflow: hidden;
  clear: both;
}



.no-border-radius {
  border-radius: 0 !important;
}

.inner-slider-container {
  overflow: auto;
  display: block;
  width: 100%;
}

.inner-slider {
  display: inline-flex;
  max-width: 100%;
  gap: 24px;
  width: 100%;
  overflow: scroll hidden;
  white-space: nowrap;
  padding-left: 0;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
}

.inner-slider-item {
  display: inline-block;
  flex: 0 0 auto;
}

.page-content {
  margin: 0 auto;
  /* height: 100%; */
  width: 100%;
  padding: var(--ion-margin, 24px);
  display: block;

  &.small {
    max-width: 970px;
  }

  &.no-max {
    max-width: 100% !important;
  }

  &.no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.outer-content {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;

  .outer-content-abs {
    height: 100%;
    width: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}


.border-right {
  border-right: 1px solid var(--ion-border-color);
}

.border-left {
  border-left: 1px solid var(--ion-border-color);
}

ion-skeleton-text {
  --background: var(--ion-card-background);
  --background-rgb: var(--ion-sekeleton-rgb);
}

@media screen and (max-width: 767px) {
  .page-content {
    padding-bottom: var(--ion-margin, 12px);
    padding-top: var(--ion-margin, 12px);
    padding-left: var(--ion-margin, 12px);
    padding-right: var(--ion-margin, 12px);
  }
}

.page-content-full {
  margin: 0 auto;
  width: 100%;
}

.slider-item {
  width: 100%;
  display: block;
  position: relative;
  /* If you want text inside of it */
  overflow: hidden;

  border-radius: var(--app-narrow-radius);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.contact-popover {
  --width: 900px;
}

.smaller-popover {
  --width: 300px;
}

.middle-popover {
  --width: 420px;
  --max-width: 100%;
}

.menu-dropdown .popover-content {
  border-radius: 0px 0px var(--app-narrow-radius) var(--app-narrow-radius);
}


.page-toolbar {
  --background: transparent;
  --min-height: auto;
  padding-bottom: 24px;

  ion-toolbar {
    --background: transparent;
    margin: 0;
    padding: 0;
    --min-height: auto;
    --background: transparent;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--ion-border-color);
  }

  ion-segment-button {
    font-size: 13px;
    font-weight: 600;
  }

  ion-buttons {
    margin: 0;
  }

  ion-button {
    text-transform: none;
    --padding-start: 12px;
    --padding-end: 12px;
  }

  ion-select {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  ion-searchbar {
    --box-shadow: none;
    --background: transparent;
  }
}



.info-title {
  margin: 6px;
  margin-bottom: 4px;
  font-size: 25px;
}


.xp {
  font-weight: bold;
  font-style: italic;
}

.search-bar {
  --box-shadow: none;
  --color: var(--ion-text-color);
  --icon-color: var(--ion-color-step-750);
  --cancel-button-color: var(--ion-color-step-750);
  --clear-button-color: var(--ion-color-step-750);
  --border-radius: var(--app-narrow-radius);
  --background: var(--ion-background-color);

  max-width: 90%;
  max-width: 350px;

  .searchbar-input {
    font-size: 12px !important;
    line-height: 24px;
    /* padding-inline-start: 20px; */
    -webkit-padding-start: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .searchbar-clear-button {
    right: 35px;
  }

  .searchbar-search-icon {
    width: 15px;
    height: 15px;
    right: 14px;
    left: auto;
    top: 9px;
  }
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook) !important;
  --ion-color-base-rgb: var(--ion-color-facebook-rgb) !important;
  --ion-color-contrast: var(--ion-color-facebook-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-facebook-shade) !important;
  --ion-color-tint: var(--ion-color-facebook-tint) !important;
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google) !important;
  --ion-color-base-rgb: var(--ion-color-google-rgb) !important;
  --ion-color-contrast: var(--ion-color-google-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-google-shade) !important;
  --ion-color-tint: var(--ion-color-google-tint) !important;
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter) !important;
  --ion-color-base-rgb: var(--ion-color-twitter-rgb) !important;
  --ion-color-contrast: var(--ion-color-twitter-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-twitter-shade) !important;
  --ion-color-tint: var(--ion-color-twitter-tint) !important;
}

.ion-color-github {
  --ion-color-base: var(--ion-color-github) !important;
  --ion-color-base-rgb: var(--ion-color-github-rgb) !important;
  --ion-color-contrast: var(--ion-color-github-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-github-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-github-shade) !important;
  --ion-color-tint: var(--ion-color-github-tint) !important;
}

.ion-color-steam {
  --ion-color-base: var(--ion-color-steam) !important;
  --ion-color-base-rgb: var(--ion-color-steam-rgb) !important;
  --ion-color-contrast: var(--ion-color-steam-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-steam-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-steam-shade) !important;
  --ion-color-tint: var(--ion-color-steam-tint) !important;
}

.ion-color-striked {
  --ion-color-base: var(--ion-color-striked) !important;
  --ion-color-base-rgb: var(--ion-color-striked-rgb) !important;
  --ion-color-contrast: var(--ion-color-striked-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-striked-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-striked-shade) !important;
  --ion-color-tint: var(--ion-color-striked-tint) !important;
}

.ion-color-pro {
  --ion-color-base: var(--ion-color-pro) !important;
  --ion-color-base-rgb: var(--ion-color-pro-rgb) !important;
  --ion-color-contrast: var(--ion-color-pro-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-pro-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-pro-shade) !important;
  --ion-color-tint: var(--ion-color-pro-tint) !important;
}

.module-toolbar {
  --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
}

.module-header {
  &:after {
    display: none;
  }
}

/*
ion-menu:before {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 100%;
}
ion-menu:after {
  content: ' ';
y  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 100%;
  box-shadow: 0 1px 5px 1px rgb(0 0 0 / 20%);
}
*/
ion-split-pane {
  --border: 0px transparent;
}

.home-icon {
  color: var(--ion-color-primary);
  -webkit-mask-image: url(./assets/stripes.svg);
  mask-image: url(./assets/stripes.svg);
  background-color: blue; // or any color of your choice.

  -webkit-mask-size: cover;
  mask-size: cover;
  display: block;
  width: 60px;
  height: 60px;
}

.online-state {
  --background: transparent;
  --color: transparent;
  background: var(--background);
  text-transform: capitalize;
  color: var(--color);
}

.online-state-offline {

  --background: var(--ion-color-danger);
  --color: var(--ion-color-danger-contrast);
}

.online-state-online {
  --background: var(--ion-color-success);
  --color: var(--ion-color-success-contrast);
}

.online-state-busy {
  --background: var(--ion-color-warning);
  --color: var(--ion-color-warning-contrast);
}

.inner-nav {
  padding-left: var(--ion-margin, 12px);
  padding-right: var(--ion-margin, 12px);

  ion-segment-button.segment-button-checked {
    &::part(indicator) {
      opacity: 2 !important;
      --indicator-color: var(--ion-color-primary);
    }
  }

  ion-segment-button {
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
    --padding-start: 30px;
    --padding-end: 30px;
    text-align: center;

    ion-icon {
      font-size: 20px;
    }

    &::part(indicator) {
      opacity: 1 !important;
      --indicator-color: var(--ion-border-color);
      --indicator-height: 1px;
    }
  }
}

.details-info-badge-list {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-flow: wrap;
}




.filter-title {
  padding: 0;
  margin-bottom: 0;
  --padding-start: 0;
  --inner-padding-end: 0;
  --padding-end: 0;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;
  margin-top: 0;
  color: var(--ion-text-color);
  font-size: 11px;
  text-transform: uppercase !important;

  ion-label {
    color: var(--ion-color-step-500) !important;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase !important;
  }
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.ion-hidden {
  display: none;
}

ion-content {
  --overflow: overlay;
  overflow: overlay;
  --background: var(--ion-background-color, #fff);
}

.product-star-list>ion-icon {
  width: 12px;
  height: 12px;
}

h1 {
  font-weight: bold;
  font-size: 32px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-weight: bold;
  font-size: 16px;
}

h3.component-title {
  margin-top: var(--ion-app-spacer);
  margin-bottom: var(--ion-margin, 24px);
  margin-left: 0;
  margin-right: 0;
}

.img-4-3-container {
  position: relative;
  padding-top: 75%;
  /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
  width: 100%;
  overflow: hidden;
}

.img-4-3-container-double {
  position: relative;
  padding-top: calc(75% * 2);
  /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
  width: 100%;
  overflow: hidden;
}

.img-16-9-container {
  position: relative;
  padding-top: 56.25%;
  /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
  width: 100%;
  overflow: hidden;
}

ion-grid {
  ion-col:first-child {}
}

.grid-padding {
  --ion-grid-column-padding: 10px;
}

.grid-no-padding {
  --ion-grid-padding: 0px;
  padding: 0;
  margin: 0;


  >ion-row {
    padding: 0;
    margin: 0;

    >ion-col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.img-50 {
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding: 0;
  display: block;
  position: relative;
}

.img-75 {
  width: 75px;
  height: 75px;
  overflow: hidden;
  padding: 0;
  display: block;
  position: relative;
}

.nav-content {
  --background: transparent;
  background: transparent;

  ion-item {
    --background: transparent;
    background: transparent;
  }
}

.product-slider {
  padding-bottom: 30px;

  .swiper-pagination {
    bottom: 0px !important;
    z-index: 2;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 6px;
  }
}

.product-slider-offset {
  overflow: visible;

  .swiper-pagination {
    bottom: -30px !important;
  }
}

.in-nav {
  position: relative;
  display: block;
  contain: none;
  width: 100%;

  .ion-page {
    display: block;
    position: relative;
    contain: none;
    width: 100%;
  }
}

ion-modal {
  --border-radius: var(--app-narrow-radius);

  ion-header {
    ion-toolbar {
      --height: auto;
      --min-height: 40px;
      padding-bottom: 4px;
      --padding-top: 24px;
      --background: transparent;

      ion-title {
        color: var(--ion-text-color);
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 12px;
      }
    }
  }

  .modal-footer {
    padding: 8px;
  }
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
  --backdrop-opacity: 0.5;
  --box-shadow: none;
}

ion-modal::part(content) {
  border: 1px solid var(--ion-border-color);
}

.modal-footer {
  display: flex;
  flex-direction: row;
  padding: var(--ion-margin, 4px) var(--ion-margin, 12px);
  justify-content: space-between;

  ion-grid,
  ion-row,
  ion-col {
    margin: 0;
    padding: 0;
  }

  &::before {
    display: none;
  }

  border-top: 1px solid var(--ion-border-color);
}

.editor-button {
  --box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 32px;
  height: 32px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px;
}

.hero-gradient-bg {
  background: #232323;
}

.hero-orbit-bg {
  clip-path: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  background-size: contain;
  background: radial-gradient(ellipse at bottom, var(--ion-card-background-hover) 0%, var(--ion-background-color) 100%);
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 0;
}

.hero-orbit-bg {
  background: rgb(30 30 30);
}