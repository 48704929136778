.only-on-mobile {
  display: none !important;
}




@media only screen and (max-width: 900px) {
  .only-on-desktop {
    display: none !important;
  }

  .only-on-mobile {
    display: block !important;
  }
}