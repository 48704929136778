.footer {

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }


  .footer__content {
    flex: 1;
    padding-top: 40px;

  }

  .footer__cols {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    justify-content: space-between;
  }

  .footer__col {
    flex: 1;
  }

  .footer__links {
    font-weight: 400;
    color: inherit;
  }

  .footer__title {
    margin-bottom: 20px;
    font-size: 16px;
    margin-top: 0;
    font-weight: 800;
  }

  .footer__outro {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid var(--ion-border-color);
    font-size: 14px;
    flex-wrap: wrap;
  }

  .footer__links {
    font-weight: 400;
    color: inherit;
    color: var(--ion-color-step-550);

  }

  .footer__links.is-horizontal {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .footer__aside {
    max-width: 285px;
    flex: 1;

    color: var(--ion-color-step-550);
  }



  .footer__links.is-horizontal li+li {
    margin-top: 0;
  }

  .footer__links.is-horizontal li {
    position: relative;
  }

  .footer__inner {
    display: flex;
    margin-bottom: 57px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 60px;
  }

  .logo-alt {
    display: flex;
    width: 141px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer__logo {
    margin-bottom: 19px;
  }

  .footer__links.is-horizontal li {
    position: relative;
  }

  .footer__links a {
    position: relative;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    padding: 6px 0px;
    color: var(--ion-color-step-550);
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .footer__links a:before {
    content: "";
    position: absolute;
    left: 0;

    bottom: -1px;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: width .2s;
  }

  .footer__links a:hover {
    color: var(--ion-color-primary);
  }

  .footer__links a:hover:before {
    width: 100%;
  }
}


@media (max-width: 800px) {
  .footer .footer__aside {
    min-width: 100%;
    max-width: 100%;
    flex: 1;
  }
}