html {
  scroll-behavior: smooth;
}

ion-popover::part(content),
*,
body,
html {


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    overflow: hidden;
    z-index: 9999;
  }

  ::-webkit-scrollbar-track:vertical {
    border-radius: var(--app-narrow-radius);
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:vertical {
    border-radius: var(--app-narrow-radius);
    cursor: pointer;
  }

  ::-webkit-scrollbar-track:horizontal {
    border-radius: var(--app-narrow-radius);
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: var(--app-narrow-radius);
    cursor: pointer;
  }

  ::-webkit-scrollbar-corner,
  ::-webkit-resizer {
    background: inherit;
  }

  &:hover::-webkit-scrollbar-track:vertical {
    z-index: 9999;
  }

  &:hover::-webkit-scrollbar-thumb:vertical {
    background: var(--ion-scrollbar-color);
  }

  &:hover::-webkit-scrollbar-track:horizontal {
    background: var(--ion-scrollbar-color);
  }

  &:hover::-webkit-scrollbar-thumb:horizontal {
    background: var(--ion-scrollbar-color);
  }

  &:hover::-webkit-scrollbar-corner,
  &:hover::-webkit-resizer {
    background: var(--ion-scrollbar-color);
  }
}

ion-popover::part(content) {
  scrollbar-width: auto;
  z-index: 9999;
}

ion-content {
  &::part(scroll) {
    overscroll-behavior-y: unset;
    background-color: blue Im !important;
  }
}

.scrollable,
.native-textarea {
  scrollbar-width: auto;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
  touch-action: auto;

  &:hover::-webkit-scrollbar-track:vertical {
    z-index: 99;
    border-radius: var(--app-narrow-radius);
  }


  &:hover::-webkit-scrollbar-track:horizontal {
    z-index: 99;
    border-radius: var(--app-narrow-radius);
  }

  &:hover::-webkit-scrollbar-thumb:vertical {
    background: var(--ion-scrollbar-color);
    border-radius: var(--app-narrow-radius);
  }


  &:hover::-webkit-scrollbar-thumb:horizontal {
    background: var(--ion-scrollbar-color);
    border-radius: var(--app-narrow-radius);
  }

  &:hover::-webkit-scrollbar-corner,
  &:hover::-webkit-resizer {
    background: var(--ion-scrollbar-color);
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    overflow: hidden;
    z-index: 99;
  }

  &.visible {


    &::-webkit-scrollbar-track:vertical {
      z-index: 99;
      border-radius: var(--app-narrow-radius);
    }


    &::-webkit-scrollbar-track:horizontal {
      z-index: 99;
      border-radius: var(--app-narrow-radius);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background: var(--ion-scrollbar-color);
      border-radius: var(--app-narrow-radius);
    }


    &::-webkit-scrollbar-thumb:horizontal {
      background: var(--ion-scrollbar-color);
      border-radius: var(--app-narrow-radius);
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
      background: var(--ion-scrollbar-color);
    }

  }

  &::-webkit-scrollbar-track:vertical {
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:vertical {
    cursor: pointer;
  }

  &::-webkit-scrollbar-track:horizontal {
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    cursor: pointer;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    background: inherit;
  }

}