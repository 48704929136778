.mac-title {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 6px;


  .close {
    background: #ff5c5c;
    font-size: 9pt;
    width: 14px;
    height: 14px;
    border: 1px solid #e33e41;
    border-radius: 50%;
    display: inline-block;
  }

  .close:active {
    background: #c14645;
    border: 1px solid #b03537;
  }

  .close:active .closebutton {
    color: #4e0002;
  }

  .closebutton {
    color: #820005;
    visibility: hidden;
    cursor: default;
  }

  .minimize {
    background: #ffbd4c;
    font-size: 9pt;
    line-height: 11px;
    width: 14px;
    height: 14px;
    border: 1px solid #e09e3e;
    border-radius: 50%;
    display: inline-block;
  }

  .minimize:active {
    background: #c08e38;
    border: 1px solid #af7c33;
  }

  .minimize:active .minimizebutton {
    color: #5a2607;
  }

  .minimizebutton {
    color: #9a5518;
    visibility: hidden;
    cursor: default;
  }

  .zoom {
    background: #00ca56;
    font-size: 9pt;
    line-height: 11px;
    width: 14px;
    height: 14px;
    border: 1px solid #14ae46;
    border-radius: 50%;
    display: inline-block;
  }

  .zoom:active {
    background: #029740;
    border: 1px solid #128435;
  }

  .zoom:active .zoombutton {
    color: #003107;
  }

  .zoombutton {
    color: #006519;
    visibility: hidden;
    cursor: default;
  }
}

.mac-title:hover a {
  visibility: visible;
}