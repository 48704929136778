.os-badge {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: var(--app-narrow-radius);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 12px;

  ion-icon {
    --ion-color-base: white;
  }

  span {}

  &.os-badge-xs {
    height: 18px;
    width: 18px;

    ion-icon {
      font-size: 12px;
    }
  }

  &.os-badge-label {
    height: auto;
    width: auto;
    display: inline-flex;
    gap: 6px;
    align-items: center;

    font-weight: 500;
    padding: 6px 10px;
    font-size: 13px;

    ion-icon {
      font-size: 14px;
    }
  }
}

.os-badge.windows {
  background: var(--ion-color-windows);

  ion-icon {
    --ion-color-base: var(--ion-color-windows-contrast);
  }

  color: var(--ion-color-windows-contrast);
}

.os-badge.linux {
  background: var(--ion-color-tux);

  ion-icon {
    --ion-color-base: var(--ion-color-tux-contrast);
  }

  color: var(--ion-color-tux-contrast);
}

.os-badge.chrome {
  background: var(--ion-color-chrome);

  ion-icon {
    --ion-color-base: var(--ion-color-chrome-contrast);
  }

  color: var(--ion-color-chrome-contrast);
}


.os-badge.apple {
  background: var(--ion-color-apple);

  ion-icon {
    --ion-color-base: var(--ion-color-apple-contrast);
  }

  color: var(--ion-color-apple-contrast);
}

.os-badge.xbox {
  background: var(--ion-color-xbox);

  ion-icon {
    --ion-color-base: var(--ion-color-xbox-contrast);
  }

  color: var(--ion-color-xbox-contrast);
}

.os-badge.ps {
  background: var(--ion-color-ps);

  ion-icon {
    --ion-color-base: var(--ion-color-ps-contrast);
  }

  color: var(--ion-color-ps-contrast);
}

.os-badge.nintendo {
  background: var(--ion-color-nintendo);

  ion-icon {
    --ion-color-base: var(--ion-color-nintendo-contrast);
  }

  color: var(--ion-color-nintendo-contrast);
}


.os-badge.steam {
  background: var(--ion-color-steam);

  ion-icon {
    --ion-color-base: var(--ion-color-steam-contrast);
  }

  color: var(--ion-color-steam-contrast);
}

.os-badge.striked {
  background: var(--ion-color-striked);

  ion-icon {
    --ion-color-base: var(--ion-color-striked-contrast);
  }

  color: var(--ion-color-striked-contrast);
}