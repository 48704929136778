.item-interactive-disabled {
  // opacity: 0.3;
}

ion-textarea,
ion-input,
ion-select {
  --placeholder-opacity: 0.7;
}

.input-element {

  .ionic-selectable-label-stacked,
  .ionic-selectable-label-floating {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
  }

  .item-label-stacked.sc-ion-input-md-h,
  .item-label-stacked .sc-ion-input-md-h,
  .item-label-floating.sc-ion-input-md-h,
  .item-label-floating .sc-ion-input-md-h {
    font-size: 16px;
  }

  .label-stacked {
    text-transform: uppercase !important;
    font-weight: 700 !important;
    color: #929292 !important;
    font-size: 15px !important;
    margin-bottom: 16px;
    display: block;
  }

  .item-label-stacked.sc-ion-textarea-md-h,
  .item-label-stacked.sc-ion-input-md-h,
  .item-label-stacked .sc-ion-input-md-h,
  .item-label-floating.sc-ion-input-md-h,
  .item-label-floating .sc-ion-input-md-h,
  .ionic-selectable-label-stacked,
  .ionic-selectable-label-floating,

  ion-input,
  ion-select {

    --border-color: var(--ion-border-color);

    --background: var(--input-background);
    --border-radius: var(--app-narrow-radius);
    border-radius: var(--app-narrow-radius);
    --padding-start: 12px !important;
    font-size: 14px;
    --placeholder-opacity: 0.5;
    font-weight: 400;

    min-height: 35px;


  }

  .item-label-stacked .sc-ion-textarea-md-h,
  .item-label-floating.sc-ion-textarea-md-h,
  .item-label-floating .sc-ion-textarea-md-h,
  ion-textarea {

    --border-color: var(--ion-border-color);
    --background: var(--input-background);
    border-radius: var(--app-narrow-radius);
    --border-radius: var(--app-narrow-radius);
    --placeholder-opacity: 0.5;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-top: 8px !important;
    --padding-bottom: 8px !important;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    --height: auto;
    --min-height: auto;
    height: auto;
    min-height: auto;

    textarea {
      --placeholder-opacity: 0.5;
      --height: auto;
      --min-height: auto;
      height: auto;
      min-height: auto;


    }

  }

  ion-select::part(icon) {
    transform: translate3d(0px, 0px, 0px);
    margin-right: 10px !important;
  }
}

.option-item {
  --border-color: var(--ion-color-dark);
}