.markdown-field {
  border: 1px solid var(--ion-border-color);
  padding: 0px 12px;
  border-radius: var(--app-narrow-radius);
  overflow: hidden;
  max-height: 250px;
  overflow-y: auto;

  .markdown-content {

    zoom: 0.8;
  }
}

.markdown {

  font-size: 14px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--ion-text-color);
    line-height: 1.2;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 26px;

  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;

  }

  h5 {
    font-size: 14px;

  }

  h6 {
    font-size: 10px;
  }

  small {
    font-size: 75%;
  }

  sub,
  sup {
    position: relative;

    font-size: 75%;

    line-height: 0;

    vertical-align: baseline;
  }

  p {
    line-height: 24px;
    color: var(--ion-color-step-650);
  }

  li {
    opacity: .7;
  }

  sup {
    top: -.5em;
  }

  sub {
    bottom: -.25em;
  }
}