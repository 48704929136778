.hero {
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .hero-teaser {
    background: var(--ion-color-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    /* height: 15px; */
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }

  .hero-content {
    max-width: 600px;
    padding-top: 3%;
    padding-bottom: 7%;
    z-index: 1;

    h2 {
      font-weight: bold;
      font-size: 40px;
      color: #fff;
      width: 100%;
      line-height: 50px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }

    p {
      color: var(--ion-color-step-550);
      font-size: 16px;
      margin: 0 auto;
      line-height: 26px;
      padding: 15px 0;
      font-weight: 500;
    }
  }

  .background {
    background: linear-gradient(180deg, transparent 0%, var(--ion-background-color) 100%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
  }
}

.side-heros {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  grid-auto-rows: minmax(100px, auto);
  padding-bottom: 60px;

  .side-hero {
    width: 100%;
    padding: 32px 18px;
    min-height: 400px;
    overflow: hidden;
    border-radius: var(--app-narrow-radius);
    flex: 1;

    .side-hero-description {
      font-size: 12px;
      line-height: 20px;
    }

    .side-hero-title {
      font-size: 17px;
      font-weight: 700;
    }

    .side-hero-image {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.search-hero {
  max-width: 520px;
  margin: 0 auto;

  h4 {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    margin-top: 0;
  }

  padding-top: 32px;
  padding-bottom: 32px;

  ion-searchbar {
    margin: 24px 0px;
    --background: var(--ion-card-background-hover);
    --box-shadow: none;
    --border-radius: var(--app-narrow-radius);

    /* padding-inline-start: 19px; */
    padding: 0;
  }
}