.landing-inner {
  width: 100%;
  max-width: var(--max-page-size);
  margin: 0 auto;
  padding-left: var(--ion-margin, 12px);
  padding-right: var(--ion-margin, 12px);
}

.landing-section {
  padding-top: 120px;
  padding-bottom: 120px;
  margin: 0 auto;

  &.padding-xs {

    padding-top: 80px;
    padding-bottom: 85px;
  }

  .col-70 {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: opacity .6s .1s,
  -webkit-transform .6s ease;
  transition: opacity .6s .1s,
  -webkit-transform .6s ease;
  transition: opacity .6s .1s,
  transform .6s ease;
  transition: opacity .6s .1s,
  transform .6s ease,
  -webkit-transform .6s ease;

  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.landing-masonry {
  min-width: 400px;
  border-radius: 6px;
  background-color: var(--ion-card-background-hover);

  .masonry-item {

    overflow: hidden;
    border: 1px solid var(--ion-border-color);
    width: 200px;

    .size-1 {
      position: relative;
      padding-top: 133.33333333%;
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }

    .size-2 {
      position: relative;
      padding-top: 66.5%;
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }



    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
      top: 0;
      left: 0;
      object-fit: cover;
    }


  }


}



.landing-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  h2,
  p {
    max-width: 400px;
  }

  .landing-preview-left {

    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    align-items: flex-start;

  }


  .landing-preview-full {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2,
    p {
      max-width: 100% !important;
      text-align: center;
    }
  }

  .landing-preview-right {
    flex: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: flex-end;

  }

  .flex-125 {
    flex: 1.25;
  }

  .flex-none {
    flex: none;
  }

  &.reversed {
    .landing-preview-left {
      align-items: flex-start;
    }

    .landing-preview-right {
      align-items: flex-end;
    }
  }

}

@media screen and (max-width: 767px) {

  h2,
  p {
    max-width: 100% !important;
  }

  .landing-inner {

    padding-left: var(--ion-margin, 24px);
    padding-right: var(--ion-margin, 24px);
  }

  .landing-preview {
    h2 {
      font-size: 23px !important;
      line-height: 24px !important;
    }

    p {
      font-size: 16px !important;
      line-height: 26px !important;

    }

    .landing-preview-left,
    .landing-preview-right {
      width: 100%;

      img {
        width: 100%;
      }
    }

    flex-direction: column;
    align-items: center;
    row-gap: 32px;

    &.reversed {

      flex-direction: column-reverse;
    }
  }
}



.landing-hero {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
  --ion-text-color: #fff;
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  background: var(--ion-background-color);
  background: linear-gradient(to right, #1f243d, #27877a);

  canvas {
    height: 100%;
    width: 100%;
    position: absolute;
  }


  .landing-hero-inner {
    margin: 0 auto;
    z-index: 2;
    /* flex-basis: calc(49.95% - 16px); */
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    max-width: 750px;
    flex-wrap: wrap;
    width: 100%;
    /* width: calc(49.95% - 16px); */
    padding: 120px var(--ion-margin, 24px);

    .hero-action {
      width: auto;
      margin: 0 auto;
      display: inherit;
      display: flex;
      flex-direction: row;
      margin-top: 32px;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }

    h1 {
      font-weight: 800;
      font-size: 65px;
      overflow-wrap: anywhere;
      color: var(--ion-text-color);
      letter-spacing: -2.4px;
      margin-top: 0;
      padding: 0;
    }

    h6 {
      color: var(--ion-color-step-750);
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
      padding-top: 6px;
      padding-bottom: 20px;
      color: var(--ion-color-step-650);
      max-width: 800px;
      margin: 0 auto;
    }
  }
}



.landing-key-features {
  color: var(--ion-text-color);
  font-size: 20px !important;
  font-weight: 800;
  margin-top: 20px;

  line-height: 29px;

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  ul {
    font-weight: normal;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 12px;

    li {
      margin: 6px 0px;
      position: relative;
      padding-left: 34px;

      &:before {
        content: "";
        position: absolute;
        left: 6px;
        top: 8px;
        transform: rotate(45deg);
        height: 14px;
        width: 6px;
        border-bottom: 2px solid var(--ion-text-color);
        border-right: 2px solid var(--ion-text-color);
      }
    }
  }

  span {
    padding-left: 6px;
    font-size: 18px;
    color: var(--ion-color-step-650);
  }
}

.landing-service-grid {
  margin: 0px -12px;
}

.landing-feature-grid {
  margin: 0px -24px;
}

.landing-feature-container {


  .feature-col {
    --ion-grid-column-padding: 24px !important;
  }

  .feature-containe-right-inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }

  &.right {
    flex-direction: row-reverse;

    .feature-containe-left {
      margin-left: 32px;
      margin-right: 0;
    }
  }


  .service-item {

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      font-weight: 400;
      color: var(--ion-color-step-650);

    }

    height: 100%;
    display: flex;
    position: relative;
    border-radius: 8px;
    padding: 0 20px 0;

    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;


    &:after {
      content: "";
      height: 5px;
      width: 100%;
      left: 0;
      bottom: -5px;
      opacity: 0;
      z-index: 0;
      transition: 0.25s;
      position: absolute;
    }

    &:hover::after {
      opacity: 1;
      bottom: 0;
    }

    ion-icon {
      font-size: 40px;
      display: inline-block;
      margin-bottom: 20px;
      color: #fff;
      margin-top: 0;
      background: linear-gradient(to right, #5079b2, #278378);
      border-radius: 14px;
      padding: 10px;
      margin-top: 24px;
    }


    img {
      height: 70px;
      display: inline-block;
      margin-bottom: 21px;

      filter: brightness(0%);
    }
  }

  ion-icon {
    font-size: 32px;
    color: var(--icon-color);
  }

  h4 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
    --heading-color: var(--ion-text-color);
    font-weight: 600;
  }

}


.code-box {
  .code-outer {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }

  .code-body {
    -ms-overflow-style: none;
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
    overflow: auto;
    scrollbar-width: none;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: inline-block;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    word-wrap: break-word;
    background-color: inherit;
    color: #fff;
    text-shadow: none;

    pre {
      font-style: normal;
      white-space: pre;
      margin: 0.5em 0;
      overflow: auto;
      word-wrap: break-word;
      display: block;
      background-color: inherit;
      color: #fff;
      text-shadow: none;
      padding: 1em;
      word-break: normal;
      word-spacing: normal;
      tab-size: 4;
      text-align: left;
      hyphens: none;
      line-height: 1.5;
      font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
      font-size: 1em;
      font-weight: 0;
      letter-spacing: 0;
    }

    .token.attr-name,
    .token.builtin,
    .token.char,
    .token.inserted,
    .token.selector,
    .token.string {
      color: #690;
    }

    .token.punctuation {
      color: #999;
    }

    .token.important,
    .token.regex,
    .token.variable {
      color: #e90;
    }

    .token.class-name,
    .token.function {
      color: #dd4a68;
    }
  }

  .code-header {
    align-items: center;
    color: #fff;
    display: flex;
    padding: 1rem 1rem 0;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    justify-content: flex-start;
    flex-flow: row wrap;

    .codebox-actions {
      align-items: center;
      display: flex;
      height: 2rem;
      margin-right: 1rem;

      .codebox-action {
        background-color: #3d4b61;
        border-radius: 1rem;
        height: 0.75rem;
        margin-right: 0.5rem;
        width: 0.75rem;
      }

      .codebox-action-primary {
        color: var(--ion-color-primary);
      }
    }
  }

  .code-inner {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-self: stretch;
    background-color: #0a1b25;
    border-radius: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    min-height: 32rem;
    padding: 0 1rem;
    width: inherit;
  }
}

.landing-usps-short {
  text-align: center;

  ion-grid {
    margin: 0px -12px;
  }



  .short-item {
    display: flex;
    position: relative;
    border-radius: 8px;
    height: 100%;

    padding: 16px;
    margin-right: 0;
    margin-left: 0;
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;

    &.dark-bg {
      background: var(--ion-teaser-color);
    }

    .upper-left {}

    &:after {
      content: "";
      height: 5px;
      width: 100%;
      left: 0;
      bottom: -5px;
      opacity: 0;
      z-index: 0;
      transition: 0.25s;
      position: absolute;
    }

    &:hover::after {
      opacity: 1;
      bottom: 0;
    }

    h3,
    h3 a {
      font-size: 16px !important;
      color: var(--ion-text-color) !important;
      font-weight: 600 !important;
      padding: 0;
      line-height: 1.3;
      margin: 0;
    }


    ion-icon {
      font-size: 28px;
      display: inline-block;
      margin-right: 12px;

      color: var(--icon-color);
    }

    &:hover {
      ion-icon {
        color: var(--ground-color);
      }
    }
  }

}

.landing-usps {

  ion-grid {
    margin: 0px -12px;
  }

  .usp-item {
    display: flex;
    height: 100%;
    position: relative;
    border-radius: 8px;
    padding: 0px 30px 0px;
    margin-right: 0;
    margin-left: 0;

    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;

    &:after {
      content: "";
      height: 5px;
      width: 100%;
      left: 0;
      bottom: -5px;
      opacity: 0;
      z-index: 0;
      transition: 0.25s;
      position: absolute;
    }

    &:hover::after {
      opacity: 1;
      bottom: 0;
    }

    h3,
    h3 a {
      margin-bottom: 10px !important;
      font-size: 26px !important;
      color: var(--ion-text-color) !important;
      font-weight: 700 !important;
      padding: 0;
      line-height: 1.3;
      margin-top: 0;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      font-weight: 400;
      color: var(--ion-color-step-650);
    }

    ion-icon {
      font-size: 40px;
      display: inline-block;
      margin-bottom: 20px;
      color: #fff;
      margin-top: 0;
      background: linear-gradient(to right, #5079b2, #278378);
      border-radius: 14px;
      padding: 10px;
      margin-top: 24px;
    }


    img,
    svg {
      height: 70px;
      display: inline-block;
      margin-bottom: 21px;
      stroke-width: 0.6px;
      stroke: #1d1c1d;
    }
  }

}

.gradient-landing-header {
  background: linear-gradient(to right, #FFDC80, #30e1e1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-1 {
  background: -webkit-linear-gradient(-66deg, #23bfb8 10%, #85ea80 100%);
  background: linear-gradient(-66deg, #23bfb8 10%, #85ea80 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0;
  -webkit-text-stroke-color: transparent;
}