.btn {
  --background: transparent;
  --background-opa: rgba(var(--ion-color-primary-rgb), 0.15);
  --color: var(--ion-color-primary);

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  background: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  -webkit-app-region: no-drag;
  border-radius: 3px !important;
  color: var(--color);
  font-size: 12px;
  color: var(--color);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.85px;


  ion-icon {
    color: var(--color);
    pointer-events: none;
  }

  background: var(--background);

  &:hover {
    background: var(--background-opa);
  }

  &:focus {
    background: var(--background-opa);
  }
}

.btn:disabled,
.btn[disabled] {
  opacity: 0.8;
  cursor: default;

  &:hover {
    background: var(--background);
  }

  &:focus {
    background: var(--background);
  }
}

.menu-button {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--ion-color-step-600);
  font-size: 23px;
  background: transparent;
  min-width: 35px;
  justify-content: center;

  &:hover {
    color: var(--ion-text-color);
  }
}

.btn-xs {
  padding: 10px 20px;
  height: 35px;
  font-size: 12px;
  line-height: 13px;


  ion-icon {
    font-size: 20px;
  }
}

.btn-xl {
  padding: 16px 32px;
  height: 45px;
  font-size: 12px;

  ion-icon {
    font-size: 20px;
  }
}



.btn-xxl {
  padding: 16px 32px;
  height: 50px;
  font-size: 15px;

  ion-icon {
    font-size: 20px;
  }
}

.btn-xxs {
  padding: 10px 15px;
  height: 32px;
  font-size: 10px;
  letter-spacing: 0.65px;

  ion-icon {
    font-size: 18px;
  }
}

.btn-xxxs {
  padding: 10px 6px;
  height: 19px;
  font-size: 10px;
  letter-spacing: 0.65px;

  ion-icon {
    font-size: 18px;
  }
}


.btn-fluid {
  padding: 0px 12px;
  height: 32px;
  letter-spacing: 0.65px;

  ion-icon {
    font-size: 16px;
  }

  span {

    font-size: 12px;
  }
}


.btn-menu-fluid {
  padding: 0px 20px;
  height: 32px;
  letter-spacing: 0.65px;

  ion-icon {
    font-size: 18px;
  }

  span {

    font-size: 12px;
  }
}



.btn-rounded {
  border-radius: 50% !important;

  height: 32px;
  width: 32px;
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

.btn-clear {
  background: transparent;

  span {
    color: var(--background);
  }

  ion-icon {
    color: var(--background);
  }

  &:hover {
    background: transparent;

    span {
      color: var(--background-hover);
    }

    ion-icon {
      color: var(--background-hover);
    }
  }

  &:focus {
    background: transparent;

    span {
      color: var(--background-focus);
    }

    ion-icon {
      color: var(--background-focus);
    }
  }


}



.btn-solid {
  --color: var(--ion-color-primary-contrast);
  --background-hover: var(--ion-color-primary-contrast);
  --background-focus: var(--ion-color-primary-contrast);
  color: var(--color);

  span {
    color: var(--color);
  }

  ion-icon {
    color: var(--color);
  }

  background: var(--background);

  &:hover {
    background: var(--background-hover);
    color: var(--color);
  }

  &:focus {
    background: var(--background-focus);
    color: var(--color);
  }

}


.btn-no-fill {
  --color: var(--ion-color-primary-contrast);
  background: transparent;

  &:hover {
    background: var(--background);
    color: var(--color);
  }

  &:focus {
    background: var(--background);
    color: var(--color);
  }

}

.btn-no-fill:disabled,
.btn-no-fill[disabled] {

  opacity: 0.5;

  ion-icon {}

  background: transparent !important;

  &:hover {
    background: transparent;
  }

  &:focus {
    background: transparent;
  }

}

.btn-outline {
  --color: var(--ion-color-primary-contrast);
  --background-hover: var(--ion-color-primary-contrast);
  --background-focus: var(--ion-color-primary-contrast);
  color: var(--background);

  ion-icon {
    color: var(--color);
  }

  background: transparent;
  border: 2px solid var(--background);

  &:hover {

    color: var(--color);

    ion-icon {
      color: var(--color);
    }

    background: var(--background-hover);
    border: 2px solid var(--background-hover);
  }


  &:focus {

    color: var(--color);

    ion-icon {
      color: var(--color);
    }

    background: var(--background-focus);
    border: 2px solid var(--background-focus);
  }


  &:before {
    opacity: 1.0;
  }
}

.btn-outline:disabled,
.btn-outline[disabled] {

  color: var(--background) !important;

  ion-icon {
    color: var(--background) !important;
  }

  border: 2px solid var(--background) !important;
  background: transparent !important;


}


.btn-list {
  display: flex;

  >* {
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      border-left: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      color: var(--color);
    }
  }
}

.btn-double-group {
  display: flex;

  >* {
    &:first-child {
      .btn {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    &:last-child {
      .btn {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}

.btn-only-icon {
  border-radius: 50% !important;

  ion-icon {
    font-size: initial;
  }
}

.btn-radius-50 {
  border-radius: 50px !important;
}

.btn-xl.btn-only-icon {
  height: 45px;
  width: 45px;
  overflow: hidden;
  padding: 0;
  min-width: 45px;


}

.btn-xs.btn-only-icon {
  height: 35px;
  width: 35px;
  overflow: hidden;
  padding: 0;
  min-width: 35px;


}

.btn-tiny {

  span {
    font-size: 10px;
    letter-spacing: 0.65px;
  }

  ion-icon {
    font-size: 16px;
  }
}

.btn-rounded.btn-tiny {
  border-radius: 50% !important;

  height: 26px;
  width: 26px;
  padding: 0;
}

.btn-tiny.btn-only-icon {
  height: 26px;
  width: 26px;
  overflow: hidden;
  padding: 0;
  min-width: 26px;
  font-size: 16px;

  ion-icon {
    position: absolute;
  }

}


.btn-xxs.btn-only-icon {
  height: 32px;
  width: 32px;
  overflow: hidden;
  padding: 0;
  min-width: 32px;
  font-size: 20px;

  ion-icon {
    font-size: 18px;
    position: absolute;
  }
}


.btn-side-rounded {
  border-radius: 24px !important;
}

.btn-block {
  width: 100%;
}

.btn-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contrast {
  --background: var(--ion-color-contrast);
  --background-opa: rgba(var(--ion-color-contrast-rgb), 0.15);
  --background-hover: var(--ion-color-contrast-shade);
  --background-focus: var(--ion-color-contrast-shade);
  --color: var(--ion-color-contrast-contrast);
}

.warning {
  --background: var(--ion-color-warning);
  --background-opa: rgba(var(--ion-color-warning-rgb), 0.15);
  --background-hover: var(--ion-color-warning-tint);
  --background-focus: var(--ion-color-warning-shade);
  --color: var(--ion-color-warning-contrast);
}

.default {
  --background: var(--ion-color-menu);
  --background-opa: rgba(var(--ion-color-menu-rgb), 0.15);
  --background-hover: var(--ion-color-menu-tint);
  --background-focus: var(--ion-color-menu-shade);
  --color: var(--ion-color-menu-contrast);
}

.primary {
  --background: var(--ion-color-primary);
  --background-opa: rgba(var(--ion-color-primary-rgb), 0.15);
  --background-hover: var(--ion-color-primary-tint);
  --background-focus: var(--ion-color-primary-shade);
  --color: var(--ion-color-primary-contrast);
}

.danger {
  --background: var(--ion-color-danger);
  --background-opa: rgba(var(--ion-color-danger-rgb), 0.15);
  --background-hover: var(--ion-color-danger-tint);
  --background-focus: var(--ion-color-danger-shade);
  --color: var(--ion-color-danger-contrast);
}

.tertiary {
  --background: var(--ion-color-tertiary);
  --background-opa: rgba(var(--ion-color-tertiary-rgb), 0.15);
  --background-hover: var(--ion-color-tertiary-tint);
  --background-focus: var(--ion-color-tertiary-shade);
  --color: var(--ion-color-tertiary-contrast);
}

.info {
  --background: var(--ion-color-info);
  --background-opa: rgba(var(--ion-color-info-rgb), 0.15);
  --background-hover: var(--ion-color-info-tint);
  --background-focus: var(--ion-color-info-shade);
  --color: var(--ion-color-info-contrast);
}

.success {
  --background: var(--ion-color-success);
  --background-opa: rgba(var(--ion-color-success-rgb), 0.15);
  --background-hover: var(--ion-color-success-tint);
  --background-focus: var(--ion-color-success-shade);
  --color: var(--ion-color-success-contrast);
}

.secondary {
  --background: var(--ion-color-secondary);
  --background-opa: rgba(var(--ion-color-secondary-rgb), 0.15);
  --background-hover: var(--ion-color-secondary-tint);
  --background-focus: var(--ion-color-secondary-shade);
  --color: var(--ion-color-secondary-contrast);
}

.gradient-bg-1 {
  --background: var(--ion-color-secondary);
  --background-opa: rgba(var(--ion-color-secondary-rgb), 0.15);
  --background-hover: var(--ion-color-secondary-tint);
  --background-focus: var(--ion-color-secondary-shade);
  --color: var(--ion-color-secondary-contrast);
}

.medium {
  --background: var(--ion-color-medium);
  --background-opa: rgba(var(--ion-color-medium-rgb), 0.15);
  --background-hover: var(--ion-color-medium-tint);
  --background-focus: var(--ion-color-medium-shade);
  --color: var(--ion-color-medium-contrast);
}

.menu {
  --background: var(--ion-color-menu);
  --background-opa: rgba(var(--ion-color-menu-rgb), 0.15);
  --background-hover: var(--ion-color-menu-tint);
  --background-focus: var(--ion-color-menu-shade);
  --color: var(--ion-color-menu-contrast);
}

.bg-transparent {
  --background: transparent;
  --background-opa: transparent;
  --background-hover: transparent;
  --background-focus: transparent;
}

.btn-active {
  --background: var(--background-hover) !important;

}