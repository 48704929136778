.tbl.tbl-5 {
  --column-count: 5;
}

.tbl.tbl-3 {
  --column-count: 3;
}

.tbl.tbl-6 {
  --column-count: 6;
}

.tbl.tbl-4 {
  --column-count: 4;
}

.tbl.tbl-7 {
  --column-count: 7;
}


.tbl.tbl-8 {
  --column-count: 8;
}


.tbl {
  display: grid;
  grid-template-columns: repeat(var(--column-count), minmax(0, auto));
  width: 100%;
  font-family: Inter, "Inter UI", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: var(--ion-color-step-300);
  place-items: stretch;
  -webkit-box-align: stretch;
  margin-top: 32px;

  .row.no-records {
    padding: 12px 12px;
    display: block;
  }

  .actions {
    ion-button {
      font-size: 12px;
      width: auto;
      height: auto;
      --padding-start: 6px;
      --padding-end: 6px;
      --padding-top: 6px;
      --padding-bottom: 6px;
    }
  }

  .icon {
    font-size: 21px;

    ion-icon {

      font-size: 21px;
    }
  }

  .row {
    display: contents;

    &:hover {
      .column:not(.header) {

        background: transparent;

        &:before {
          content: "";
          position: absolute;
          inset: 0px;
          transition: box-shadow 0.2s ease 0s, border 0.2s ease 0s;
          z-index: -1;
          box-sizing: border-box;
          box-shadow: rgb(0 0 0 / 8%) 0px 4px 10px, rgb(0 0 0 / 4%) 0px 1px 3px;
          clip-path: polygon(-16px -16px, calc(100% + 16px) -16px, calc(100% + 16px) 0px, 100% 0px, 50% 50%, 100% 100%, calc(100% + 16px) 100%, calc(100% + 16px) calc(100% + 16px), -16px calc(100% + 16px), -16px 100%, 0px 100%, 50% 50%, 0px 0px, -16px 0px);
        }

        &:first-child {
          &:before {

            clip-path: polygon(-16px -16px, calc(100% + 16px) -16px, calc(100% + 16px) 0px, 100% 0px, 50% 50%, 100% 100%, calc(100% + 16px) 100%, calc(100% + 16px) calc(100% + 16px), -16px calc(100% + 16px));
          }
        }
      }
    }





    .column {
      white-space: nowrap;
      padding: 12px 12px;
      align-items: center;
      position: relative;
      align-self: center;
      box-sizing: border-box;
      border-bottom: 1px solid var(--ion-border-color);
      font-size: 13px;
      line-height: 1.12;
      text-transform: none;
      letter-spacing: 0em;
      font-weight: 400;
      height: 100%;
      color: var(--ion-text-color);
      /* place-self: center; */
      display: inline-flex;
      white-space: pre-wrap;
      word-break: break-word;
    }



    .title {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      color: var(--ion-color-step-500);
      font-weight: 600;


    }


    .linked {
      cursor: pointer;
    }

    .header {
      padding-top: 4px;
      padding-bottom: 8px;
      color: var(--ion-color-step-300);
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      font-weight: bold;
    }

  }
}