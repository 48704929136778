.full-modal {
  width: 100%;
}

.full-modal::part(content) {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
}


.short-modal {
  max-width: 320px;
}

.report-popover {
  --width: 320px;
  --max-width: 320px;
  --offset-y: -12px;
}