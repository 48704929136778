input {
  outline: none;
  transition-delay: 0s;

  &:focus,
  &:autofill,
  &:hover {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;

  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: none;
  -webkit-box-shadow: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {

  transition-delay: 0s;
}