.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
}

.stepper-item {
  position: relative;
  flex-direction: column;
  text-align: center;
  width: auto;
  display: inline-flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-container {
  width: 100%;
  position: relative;
  flex: 1;
  width: 100%;
}

.stepper-container .stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 4px solid var(--ion-card-background-hover);
  width: 100%;
  top: 15px;
  left: 0%;
  z-index: 2;
}

.stepper-container .stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 4px solid var(--ion-card-background-hover);
  width: 100%;
  top: 15px;
  left: 0%;
  z-index: 2;
}

.stepper-container:first-child .stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 4px solid var(--ion-card-background-hover);
  width: 100%;
  top: 15px;
  left: 0%;
  z-index: 2;

  border-bottom: 4px solid var(--ion-card-background-hover);
}

.stepper-item {
  width: 100%;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--ion-card-background-hover);
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
}

.stepper-container.start {
  flex: 0.5;
}

.stepper-item .step-name {
  color: var(--ion-color-step-750);
  font-size: 13px;
}

.stepper-container.active .stepper-item .step-counter {
  border: 1px solid var(--ion-color-primary-shade);
}

.stepper-container.completed .step-counter {
  background-color: var(--ion-color-primary-shade);
}

.stepper-container.completed .stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 4px solid var(--ion-color-primary-shade);
  width: 50%;
  top: 15px;
  left: 0%;
  z-index: 3;
}

.stepper-container.completed .stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 4px solid var(--ion-color-primary-shade);
  width: 50%;
  top: 15px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.flex-right {
  text-align: right;
}