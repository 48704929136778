.sc-ion-alert-md-h.centered {
  --max-width: 420px;

  .alert-wrapper {
    align-items: center;
  }

  .alert-input-group,
  .alert-button-group {
    width: 100%;
  }
}

.notify-page-alert {

  --background: var(--ion-card-background);
  --color: var(--ion-color-primary-contrast);
  border-bottom: 1px solid var(--ion-border-color);
  background: var(--background);
  color: var(--color);
  font-weight: 500;
  padding: 8px 16px;
  text-align: left;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  justify-content: space-between;

  span.info {
    color: var(--ion-color-step-750);
  }

}