header.top-bar-outer {

  --text-color: var(--ion-color-step-600);
  --text-color-hover: var(--ion-text-color);

  &.fixed {
    position: absolute;
    z-index: 3;
  }


  .button-badge {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    ion-badge {
      position: absolute;
      right: 4px;
      top: 8px;
      font-size: 10px;
      min-width: 14px;
      --padding-start: 2px;
      --padding-end: 2px;
      --padding-top: 1px;
      --padding-bottom: 1px;
      font-weight: normal;
    }
  }

  .left-ui-button {
    padding-left: 12px;
  }

  .left-ui-button-outer {
    display: flex;
  }

  .right-ui-button-outer {
    display: flex;


  }

  .header-ui-button {
    background: transparent;
    height: 100%;
    padding: 10px;
    height: 55px;

    ion-icon {
      font-size: 19px;
      color: var(--text-color);
    }

    &:hover {
      color: var(--text-color-hover);

      ion-icon {
        color: var(--text-color-hover);

      }
    }
  }

  .left-ui-button,
  .right-ui-button {
    background: transparent;

    height: 100%;
    height: 55px;

    ion-icon {
      font-size: 21px;
      color: var(--text-color);
    }

    &:hover {
      ion-icon {
        color: var(--text-color-hover)
      }
    }
  }



  .more-actions {
    height: auto;
    min-height: auto;
    line-height: 20px;
    cursor: pointer;
    height: 20px;
    border: 1px solid transparent;
    padding: 0px 10px;
    border-radius: var(--app-narrow-radius);
    font-size: 20px;
    color: var(--text-color);
    height: 50px;
    display: flex;
    align-items: center;
  }


  .header-nav {
    gap: 4px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .top-bar {
    bottom: 0;
    z-index: 1;
    min-height: 55px;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    z-index: 2;
  }


  .buttons-start {
    margin: 0;
    gap: 6px;
    align-items: center;
    display: flex;
  }




  .top-bar.electron {
    --min-height: 50px;
    padding: 0;

    ion-button,
    ion-label,
    .header-nav-item span {
      font-size: 13px;
    }

    .header-nav {
      margin-left: 0;
    }


    ion-icon {
      font-size: 16px;
    }

    .buttons-start-title {
      gap: 0;
    }

    .buttons-start-end {
      gap: 4px;
    }
  }


  display: block;
  min-height: 55px;
  height: 55px;
  z-index: 2;
  width: 100%;
  border-bottom: 1px solid var(--ion-border-color);
  background: var(--ion-background-color);

  &.transparent {
    background: transparent;
    border-bottom: 1px solid transparent;

    --text-color: var(--ion-color-step-750);
    --text-color-hover: var(--ion-text-color);
  }

  .top-bar.centered {
    margin: 0 auto;
    max-width: var(--max-page-size);

    .header-nav {
      gap: 12px !important;
    }
  }

  &.is-home.on-top {
    border-bottom: 1px solid transparent;
    background: transparent;
  }


  .header-nav-item {
    display: flex;
    align-items: center;
    height: 55px;
    font-weight: 500;
    font-size: 15px;
    padding: 14px 10px;
    border-radius: 0;
    position: relative;

    cursor: pointer;



    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 1px;
      bottom: 0px;
      width: 0;
      background: var(--ion-color-primary);
      transition: width 0.2s;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }

    span {
      opacity: 1;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: var(--text-color);
    }


    &:hover {
      span {
        color: var(--text-color-hover)
      }
    }

    &.active {
      &::before {
        position: absolute;
        width: 100%;
        background: var(--ion-color-primary);
        content: "";
        height: 1px;
        bottom: 0px;
        left: 0;
      }

      span {
        color: var(--text-color-hover)
      }
    }
  }
}