:host {
  display: block;
  width: 100%;
}

.teaser {
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: flex;
  align-content: space-between;
  align-content: stretch;
  align-items: center;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  border: 10px solid var(--ion-border-color);
  gap: 24px;
  border-image-slice: 1;
  border-width: 5px;
  flex-direction: column;
  align-items: flex-start;

  .teaser-content {
    z-index: 1;
    white-space: pre-wrap;
    flex: 1;


    p {
      color: var(--ion-color-step-650);
      margin: 0 auto;
      line-height: 26px;
      padding: 15px 0;
      font-size: 13px;
      font-weight: 300;
      line-height: 21px;
      margin-bottom: 0;
      white-space: normal;
      padding: 0;
      padding-top: 12px;
      font-size: 13px;
      font-weight: 400;
    }

    h2 {
      font-size: 17px;
      line-height: 23px;
      margin: 0;
    }



  }

  &.gradient-1 {
    background: linear-gradient(330deg, #b92762, #275587);
    border: 0px !important;


    .teaser-content {

      h2 {
        color: #fff !important;
      }

      p {
        color: rgba(255, 255, 255, 0.9) !important;
      }
    }
  }

  &.gradient-2 {
    background: linear-gradient(330deg, #273787, #27877a);
    border: 0px !important;


    .teaser-content {


      h2 {
        color: #fff !important;
      }

      p {
        color: rgba(255, 255, 255, 0.9) !important;
      }
    }
  }

  .teaser-image {
    img {
      max-height: 350px;
    }
  }

  .teaser-disable {
    background: rgb(57 59 70 / 85%);
    position: absolute;
    display: table;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    z-index: 2;

    >span {
      display: table-cell;
      z-index: 2;
      vertical-align: middle;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
  }
}