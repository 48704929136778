.draggable {
  -webkit-user-select: none;
  user-select: none;
  -webkit-app-region: drag;
}

.undraggable {
  -webkit-user-select: none;
  user-select: none;
  -webkit-app-region: no-drag;
}

ion-popover {
  -webkit-app-region: no-drag !important;
}

html,
body {
  background: transparent !important;
  overflow: hidden;
}

ion-app>.ion-page {
  background: var(--ion-background-color);
}

.electron {
  padding: 0;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 7px;
  -webkit-app-region: drag;
  border-radius: 8px;
  overflow: hidden;

  border: 1px solid #222;
  border-radius: 8px;
  overflow: hidden;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#323b44', endColorstr='#282c34', GradientType=0);
}