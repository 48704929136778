.side-menu {


  --color: var(--ion-color-step-750);
  --header-color: var(--ion-color-step-600);
  --hover-bg: var(--ion-card-background);
  --active-color: var(--ion-text-color);

  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &.side-menu-dropdown {
    padding: 4px;
    --hover-bg: rgba(255, 255, 255, 0.1);

  }



}