.grid-item {
  display: inline-block;
  flex: 0 0 auto;
}
.hgrid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  white-space: nowrap;
  width: 100%;
}

.vgrid {
  display: flex;
  flex-direction: row;
  gap: 12px;
  white-space: nowrap;
  width: 100%;
}
