.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: var(--ion-color-secondary-contrast);
  padding: 6px 8px;
  background: var(--ion-card-background-hover);
  border-radius: var(--app-narrow-radius);
  z-index: 30000;
  opacity: 0;
}

.ng-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: var(--ion-card-background-hover) transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--ion-card-background-hover) transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--ion-card-background-hover);
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--ion-card-background-hover) transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.desktop-toast {
  --max-width: 300px;
  --start: auto;
  font-size: 13px;
}