.grid-item {
  width: 100%;
  text-align: left;
  display: block;
  height: 100%;
  margin: 0;
  display: block;
  align-content: stretch;
  align-items: stretch;
  align-self: stretch;
  position: relative;

  .streched {
    flex-direction: column;
    height: 100%;
    display: flex;
    min-height: 100%;
  }

  ion-thumbnail {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    background: var(--ion-card-background);
  }

  &:hover {
    ion-thumbnail {
      filter: brightness(110%);
    }
  }

  ion-card-title {
    text-align: left !important;
  }

  ion-card-title.center {
    text-align: center !important;
  }

  .image-holder {
    --background: #0c0c0c;
  }

  .thumbnail-container {
    position: relative;
    padding-top: calc(125%);
    /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
    width: 100%;
    overflow: hidden;
    display: block;

    .left-side {
      position: absolute;
      z-index: 1;
      top: var(--ion-margin, 12px);
      left: var(--ion-margin, 12px);
      background-color: rgba(var(--ion-color-primary-rgb), 0.7);
      padding: 4px 8px;
      border-radius: var(--app-narrow-radius);
    }


    .right-side {
      position: absolute;
      z-index: 1;
      top: var(--ion-margin, 12px);
      right: var(--ion-margin, 12px);
      background-color: rgba(0, 0, 0, 0.5);
      padding: 4px 8px;
      border-radius: var(--app-narrow-radius);
    }

    .right-side-transparent {
      position: absolute;
      z-index: 1;
      top: var(--ion-margin, 12px);
      right: var(--ion-margin, 12px);
      padding: 4px 8px;
      border-radius: var(--app-narrow-radius);
    }

    .left-side-transparent {
      position: absolute;
      z-index: 1;
      top: var(--ion-margin, 12px);
      left: var(--ion-margin, 12px);
      border-radius: var(--app-narrow-radius);
    }
  }

  .item-avatar-container {
    margin-top: 35px;
  }

  .item-avatar {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;

    .avatar-obj {
      border: 5px solid var(--ion-card-background);
      border-radius: 50%;
      overflow: hidden;
    }

  }

  .inner-content {
    flex: 1;
    width: 100%;
    padding: 16px 12px;

    display: flex;
    position: relative;
    flex-direction: column;
  }

  .description {
    margin-top: 6px;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: normal;
    align-items: self-start;
    display: flex;
    flex: 3;
    line-height: 20px;
    color: var(--ion-color-step-850);

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .values {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
    font-size: 12px;
    align-items: center;
    flex: 2;
  }

  .pricing-values {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    flex: 2;
  }
}

.grid-item::part(native) {
  height: 100%;
}

.icon-list {
  width: 100%;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-size: 15px;
  font-weight: normal;
  gap: 8px;
}

.store-item-price-placeholder {
  width: 100%;
  margin: 0 !important;
}

.store-item-price {
  display: block;
  display: inline-block;

  margin-right: 6px;
}


.app-item-title {
  font-weight: bold;
  color: var(--ion-text-color);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -webkit-nowrap;
  display: block;
  font-weight: 600;
  line-height: 1.5em;
  font-size: 14px;


  &:hover {
    color: var(--ion-text-color);
    text-decoration: underline;
  }

  &:focus {
    color: var(--ion-text-color);

  }
}

.store-item-img-prices {
  position: absolute;
  display: flex;
  z-index: 1;
  top: var(--ion-margin, 12px);
  left: var(--ion-margin, 12px);
}

.store-item-img-badges {
  position: absolute;
  display: flex;
  z-index: 1;
  top: var(--ion-margin, 12px);
  right: var(--ion-margin, 12px);
}

ion-img::part(image) {
  border: 0;
  opacity: 1;
  text-indent: 100vw;
  vertical-align: -webkit-baseline-middle;
}

.store-item-img-discount {
  position: absolute;
  display: flex;
  z-index: 1;
  bottom: var(--ion-margin, 12px);
  right: var(--ion-margin, 12px);
}

//720px
@media screen and (min-width: 767px) {
  .grid-item {
    .thumbnail-container {
      position: relative;
      padding-top: calc(125%);
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }

    .thumbnail-container-higher {
      position: relative;
      padding-top: calc(85%);
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }


    .thumbnail-container-full {
      position: relative;
      padding-top: 0;
      width: 100%;
      overflow: hidden;

      ion-thumbnail {
        position: relative;
        height: auto;
      }
    }

    .thumbnail-container-small {
      position: relative;
      padding-top: calc(45%);
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }

    .thumbnail-container-middle {
      position: relative;
      padding-top: calc(70%);
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;
    }

    .thumbnail-container-large {
      position: relative;
      height: 315px;
      /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
      width: 100%;
      overflow: hidden;

      ion-img::part(image) {
        height: 100%;
      }
    }

    .thumbnail-container-full {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
}

.grid-item.full {
  height: 100%;
}

.list-item {
  --padding-bottom: 12px;
  --padding-start: 12px;
  --padding-end: 0;
  --padding-top: 12px;
  --ripple-color: transparent;

  ion-thumbnail {
    --border-radius: var(--app-narrow-radius);
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    height: 85px;
    width: 70px;

    img,
    ion-img {
      position: absolute;
      top: 0;
    }
  }

  ion-label {
    margin-top: 0;
    margin-bottom: 0;
  }

  .content {
    flex: 1;
    width: 100%;
    padding-bottom: var(--ion-margin, 8px);
    padding-top: var(--ion-margin, 8px);
    padding-left: 0;
    padding-right: 0;
  }

  .values {
    display: flex;
    align-items: center;
    margin-right: 12px;
    gap: 12px;

  }

  h2 {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }

  .description {
    margin-top: 6px;
    font-size: 13px;
    margin-bottom: 6px;
    font-weight: normal;
    align-items: self-start;
    display: flex;
    color: var(--ion-color-step-650);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    display: -webkit-box;
    text-overflow: ellipsis;
    flex: 3;
    margin-bottom: 12px;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.game-desc {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
}


.app-item-footer {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .btn-block {
    flex: 1;
    padding: 0px 6px;
    color: var(--color);
  }
}

.app-item {










  &:hover {
    .app-img-holder {
      filter: brightness(1.2);
    }
  }


  &:hover {
    --background: var(--ion-card-background-hover);
  }


  &.active {
    --background: var(--ion-card-background-hover);

    .app-img-holder {
      filter: brightness(1.2);
    }

    &:hover {
      --background: var(--ion-card-background-hover);
    }
  }



}

.app-offer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  width: 100%;
  font-weight: normal;

  .app-offer-pricetags {

    display: flex;
    gap: 6px;
    align-items: center;
  }

  .app-offer-pricetag-before {
    color: var(--ion-color-step-500);
    font-size: 12px;
    text-decoration: line-through;

  }

  .app-offer-pricetag-default {
    color: var(--ion-text-color);
    font-size: 12px;
  }
}


.app-offer-pricetag-discount {
  color: var(--ion-color-warning-contrast);
  font-size: 11px;
  background-color: var(--ion-color-warning);
  border-radius: var(--app-narrow-radius);
  padding: 2px 6px;
}

.app-item-badges {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;

}



.aspect-ratio--6x8 {
  overflow: hidden;
  position: relative;
}

.aspect-ratio--6x8>* {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.aspect-ratio--6x8:before {
  content: "";
  display: block;
  padding-top: 133.33333333%;
  width: 100%;
}


.aspect-ratio--4x3 {
  overflow: hidden;
  position: relative;
}

.aspect-ratio--4x3>* {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.aspect-ratio--4x3:before {
  content: "";
  display: block;
  padding-top: 75%;
  width: 100%;
}

.aspect-ratio--4x1 {
  overflow: hidden;
  position: relative;
}

.aspect-ratio--4x1>* {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.aspect-ratio--4x1:before {
  content: "";
  display: block;
  padding-top: 25%;
  width: 100%;
}



.aspect-ratio--4x2 {
  overflow: hidden;
  position: relative;
}

.aspect-ratio--4x2>* {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.aspect-ratio--4x2:before {
  content: "";
  display: block;
  padding-top: 40%;
  width: 100%;
}

.aspect-ratio--16x9 {
  overflow: hidden;
  position: relative;
}

.aspect-ratio--16x9>* {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.aspect-ratio--16x9:before {
  content: "";
  display: block;
  padding-top: 56.25%;
  width: 100%;
}


.center-img {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute !important;
  right: 0;
  top: 0;
  object-fit: cover;
  display: inline-block;
  transform: translateZ(1px);
  width: 100%;
  border: 0;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;

  &::part(image) {
    clip-path: inset(0.5px);
    filter: brightness(100%) !important;
  }
}

.center-aspect-img {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute !important;
  right: 0;
  top: 0;
  object-fit: cover;
  display: inline-block;
  transform: translateZ(1px);
  width: 100%;
  border: 0;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.full-width {
  width: 100%;

}

.app-item-hover {
  will-change: transform;
  /* padding: 6px; */
  border-radius: 7px;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &.no-footer {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .app-item-hover-btn {
    opacity: 1;
    transition: opacity 0.1s;
  }

  .app-item-hover-content {
    opacity: 0;
    flex-direction: column;
    transition: opacity 0.1s;
    display: flex;
    gap: 8px;
    height: 100%;
  }

  &:hover {
    .app-item-hover-btn {
      opacity: 1;
    }


    .app-item-hover-content {
      opacity: 1;
    }
  }
}

@media (min-width: 1024px) {
  .app-item-hover {
    &:hover {
      //  -webkit-transform: scale(1.05);
      //  transform: scale(1.05);
    }
  }
}

@media (min-width: 1024px) {
  .app-item-hover {
    &:hover {
      -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimize-contrast;
      transition: -webkit-transform .2s ease;
      transition: transform .2s ease;
      transition: transform .2s ease, -webkit-transform .2s ease;
    }
  }
}

.mini-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mini-list-item {
  display: flex;
  gap: 16px;
  padding: 12px 12px;
  align-items: center;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid var(--ion-border-color);

  .slot-end {
    font-size: 40px;
    color: var(--ion-color-step-500);
    font-weight: 500;
  }

  &:hover {
    .slot-end {
      color: var(--ion-color-primary);
    }
  }

  .mini-details-outer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
    justify-content: space-between;
    flex-direction: column;
  }

  .mini-details {
    width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    flex-direction: column;
  }

  .mini-title {
    font-weight: bold;
    color: var(--ion-text-color);

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-weight: 600;
    line-height: 1.5em;
    font-size: 14px;


  }

  .app-item-badges {
    margin-top: 4px;
  }

  .mini-cover-inner {
    position: relative;
    width: auto;
    height: 110px;
    width: 80px;
    overflow: hidden;
    --border-radius: var(--app-narrow-radius);
    border-radius: var(--app-narrow-radius);
    background: var(--ion-card-background);

    ion-skeleton-text {
      margin: 0;
      padding: 0;
    }



    ion-img,
    .cover-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0;
    }

    ion-img::part(image),
    img {

      object-fit: cover;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: var(--ion-card-background);
    }

    ion-img.unloaded::part(image),
    img.unloaded {
      opacity: 0;
      transition: opacity 0.1s;
    }

    ion-img.loaded::part(image),
    img.loaded {
      opacity: 1;
    }
  }



  background-color: var(--ion-card-background);


  &:hover {
    background-color: var(--ion-card-background-hover);
    overflow: hidden;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;

    .mini-title {
      color: var(--ion-color-primary);
    }



  }
}



.app-header-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  background-color: var(--ion-card-background);
  border-radius: 7px;
  overflow: hidden;
  align-items: center;

  .app-item-title {
    font-size: 15px;
    text-align: center;
  }

  .app-header-content {
    padding: 24px 12px;
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-header-background {
    width: 100%;
    background-color: var(--ion-card-background-hover);
  }

  ion-skeleton-text {
    --background: var(--ion-card-background-hover);
  }

  .app-header-socials {
    display: flex;
    gap: 6px;
    margin-top: 12px;

  }

  .app-header-stats-outer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    flex-wrap: wrap;

    .app-header-stats {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;

      .app-header-stats-count {
        font-weight: 600;
        font-size: 14px;
      }

      .app-header-stats-value {
        font-size: 13px;
        font-weight: normal;
        color: var(--ion-color-step-650);
      }
    }
  }

  .app-header-avatar {
    width: auto;
    height: 75px;
    width: 75px;
    position: relative;
    overflow: hidden;
    --border-radius: 50%;
    border-radius: 50%;
    background: var(--ion-card-background-hover);
    margin-top: -38.2px;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid var(--ion-card-background);

    &:hover {
      filter: brightness(110%);
    }

    ion-skeleton-text {
      margin: 0;
      padding: 0;
    }

    ion-img,
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0;
    }


    ion-img.unloaded::part(image),
    img.unloaded {
      opacity: 0;
      transition: opacity 0.1s;
    }

    ion-img.loaded::part(image),
    img.loaded {
      opacity: 1;
    }

    ion-img::part(image),
    .avatar-container {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}