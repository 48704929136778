ion-popover::part(content) {
  background: var(--ion-popover-background);
  border-radius: var(--app-narrow-radius);
  padding: 0;
  margin: 0;
}

ion-popover {
  --width: 200px;

  ion-item {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-bottom: 2px;
    --padding-top: 2px;
    --border-color: rgba(var(--ion-color-light-rgb), 0.1);
  }
}

.action-menu {
  ion-item {
    border: 0;
    --border-width: 0;
    --border-color: transparent;
    --inner-padding-end: 0;
    --border-color: rgba(var(--ion-color-light-rgb), 0.1);

    --background-hover: var(--ion-card-background-hover);

    ion-icon {
      padding: 0;
      margin: 0;
      margin-right: 12px;
      font-size: 20px;
    }

    ion-label {
      padding: 0;
      margin: 0;
      font-weight: 600;
    }
  }
}



.action-menu {
  padding: 6px;

  ion-item {
    font-size: 12px;
    --min-height: 0;
    font-weight: 600;
    --padding-start: 4px;
    --padding-end: 4px;
    --background-hover: var(--ion-border-color);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --border-width: 0 !important;
    --border-radius: 8px;
    --color: var(--ion-color-step-750);
    --color-hover: var(--ion-text-color);

    ion-icon {
      color: var(--ion-color-step-750);
    }

    &:hover {
      ion-icon {
        color: var(--ion-text-color);
      }
    }

    &:last-child {
      --border-width: 0 !important;
    }
  }

  ion-select-option {
    font-size: 12px;
    --min-height: 40px;
    font-weight: 600;
  }
}

.action-menu {
  .active {
    --background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary-contrast);
    --color-focus: var(--ion-color-primary-contrast);
    --background-hover: var(--ion-color-primary);

    ion-icon {
      color: var(--ion-color-primary-contrast);
    }
  }
}

.dropdown {
  .action-menu {
    ion-item {
      font-size: 14px;
    }
  }
}

ion-button.action-menu {
  --background-hover: transparent;
  --background: transparent;
  --background-hover-opacity: 0;
  --ripple-color: transparent;
  --padding-start: 0.1em;
  --padding-end: 0.1em;

  &:hover {
    ion-icon {
      color: var(--ion-text-color);
    }
  }
}


.select-popover {


  ion-select-popover>ion-list {
    ion-item {
      --background-activated: rgba(255, 255, 255, 0.04) !important;
      --ripple-color: transparent !important;
      --background-focused: transparent !important;
      --background-hover: rgba(255, 255, 255, 0.04) !important;

      --inner-border-width: 0;
      font-size: 14px;
      --padding-start: 8px;
      --padding-end: 8px;
      --padding-bottom: 4px;
      --padding-top: 4px;
      --height: auto;
      --min-height: auto;

      ion-checkbox {
        margin: 6px 12px;
        --border-color-checked: var(--ion-color-primary-tint);
        margin-left: 0;
      }

      ion-radio {
        margin: 6px 12px;
      }
    }

    ion-item.item-radio-checked {
      --background: var(--ion-color-primary) !important;
      --color: var(--ion-text-color) !important;
    }
  }
}


.filter-popover {
  left: -9px;
  top: 10px;


  ion-select-popover>ion-list {
    padding: 0;

    ion-item {
      --background-activated: rgba(255, 255, 255, 0.04) !important;
      --ripple-color: transparent !important;
      --background-focused: transparent !important;
      --background-hover: rgba(255, 255, 255, 0.04) !important;

      --inner-border-width: 0;
      font-size: 12px;
      --padding-start: 4px;
      --padding-end: 4px;
      --padding-bottom: 6px;
      --padding-top: 6px;
      --height: auto;
      --min-height: auto;

      ion-checkbox {
        margin: 6px 12px;
        --border-color-checked: var(--ion-color-primary-tint);
        margin-left: 0;
      }

      ion-label {
        text-overflow: ellipsis;
        text-overflow: unset;
        white-space: break-spaces;
      }

      ion-radio {
        margin: 6px 12px;
      }
    }

    ion-item.item-radio-checked {
      --background: var(--ion-color-primary) !important;
      --color: var(--ion-text-color) !important;
    }
  }
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  min-width: 150px;
  background: var(--ion-popover-background);
  border-radius: var(--app-narrow-radius);
  border: 1px solid var(--ion-border-color);
  margin-top: -10px;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 1px 5px 1px rgb(0 0 0 / 20%);

  .list-md,
  ion-list {
    padding: 8px;
  }
}

.dropdown .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: .2s, 0s;
  transition-delay: 0s, .2s;
}

.dropdown.show {
  .header-nav-item {

    span {
      color: var(--text-color-hover)
    }
  }

  .more-actions {

    ion-icon {
      color: var(--text-color-hover)
    }
  }
}

.dropdown.show .dropdown-content {

  visibility: visible;
  opacity: 1;
  transition-delay: 0s, 0s;
  display: block;
}

.tippy-box[data-theme~='transparent'] {
  background-color: var(--ion-left-background-favorites);
  border-radius: 0px 4px 4px 0px;
  margin-left: 1px;
  border: 1px solid var(--ion-border-color);
}