.app-rating-tiny {
  width: 100%;
  display: flex;
  place-content: space-between;
  align-items: center;

  .stars {
    display: flex;
    align-items: center;
  }

  .ratings {
    display: inline-flex;
    gap: 6px;
    align-items: center;
  }

  .rating-star-icon {
    color: var(--ion-color-warning);
    opacity: 1.0;
    height: 18px;
    width: 18px;
  }

  .unrated-star-icon {
    color: var(--ion-text-color);
    opacity: 0.3;
    height: 18px;
    width: 18px;
  }

  .rating-sum {
    color: var(--ion-color-step-750);
    font-size: 12px;
    font-weight: normal;
    margin-left: 6px
  }

  .rating-q {
    color: var(--ion-color-step-750);
    font-size: 13px;
    font-weight: 600;
  }
}

.app-rating-xl {
  width: 100%;
  display: flex;
  place-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .stars {
    display: flex;
    align-items: center;
  }

  .ratings {
    display: inline-flex;
    gap: 6px;
    align-items: center;
  }

  .rating-sum {
    color: var(--ion-color-step-750);
    font-size: 14px;
    font-weight: normal;
    margin-left: 6px
  }

  .rating-q {
    color: var(--ion-color-step-750);
    font-size: 16px;
    font-weight: 600;
  }
}




.five-pointed-star {
  --star-color: var(--ion-color-menu);
  --star-color-selected: var(--ion-color-warning);
  --size: 30px;
  --spacing: 3px;
  --rating: 0;
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: var(--spacing);
    background: linear-gradient(90deg, var(--star-color-selected) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.xs {
    --size: 22px;
    --spacing: 2px;
  }
}

.rating-singles {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.rating-single {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  width: 100%;
  font-size: 13px;

  .single-progress {
    width: 100%;
    flex: 1;
    padding: 4px 8px;
    font-size: 13px;
    margin-left: 12px;
    font-weight: normal;
    --rating: 0;
    --bg: black;
    --percent: calc(var(--rating) / 1 * 100%);
    background: linear-gradient(90deg, var(--bg) var(--percent), transparent var(--percent));

    &.index-5 {

      --bg: #6ea952;
    }

    &.index-4 {

      --bg: #52a99f;
    }

    &.index-3 {

      --bg: var(--ion-color-warning);
    }

    &.index-2 {

      --bg: #f36363;
    }

    &.index-1 {

      --bg: var(--ion-color-danger);
    }
  }
}

.single-pointed-star {
  --star-color: var(--ion-color-menu);
  --star-color-selected: var(--ion-color-warning);
  --size: 30px;
  --spacing: 3px;
  --rating: 0;
  --percent: calc(var(--rating) / 1 * 100%);

  display: inline-block;
  font-size: var(--size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★';
    letter-spacing: var(--spacing);
    background: linear-gradient(90deg, var(--star-color-selected) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.xs {
    --size: 22px;
    --spacing: 2px;
  }

  &.xl {
    --size: 40px;
    --spacing: 2px;
  }
}