// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  --max-page-size: 1140px;
  --mobile-size: 900px;
  --ion-font-family: Inter, "Inter UI", Helvetica, Arial, sans-serif;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --font-size-base: 14px;
  --font-size-large: 14px;
  --font-size-small: 9px;
  --page-actions-padding: 5px;
  --ion-menu-bg-color: #7e7e7e;

  --placeholder-opacity: 0.7;
  --ion-item-background: transparent;

  --app-narrow-radius: 3px;
  --ion-safe-area-left: 10px;
  --ion-safe-area-right: 10px;
  --ion-app-spacer: 40px;
  --ion-backdrop-color: rgb(0 0 0);
  --ion-backdrop-opacity: 0.75;

  --ion-toolbar-background: #1d2128;
  --ion-menu-background: #1d2128;


  --input-background: #22252a;
  --ion-shadow-color: #999;

  --ion-toolbar-color: #a7a7a7;

  --ion-menu-bg-color: #969696;

  /** menu */
  --ion-color-menu: #8d8d8d;
  --ion-color-menu-rgb: 141, 141, 141;
  --ion-color-menu-contrast: #ffffff;
  --ion-color-menu-contrast-rgb: 255, 255, 255;
  --ion-color-menu-shade: #7c7c7c;
  --ion-color-menu-tint: #c1c1c1;



  --ion-header-background: #131417;
  --ion-notify-background: #181818;

  --ion-card-background: #1c1e23;
  --ion-card-background-rgb: 28, 30, 35;
  --ion-card-background-hover: #1f2126;
  --ion-card-background-hover-rgb: 31, 33, 38;
  --ion-left-background: #16181b;
  --ion-bg-darker: #1d2125;
  --ion-right-background: #16181b;
  --ion-left-background-favorites: #131417;
  --ion-border-color: #1f2126;
  --ion-teaser-color: #3b434a;

  --ion-background-color: #16181b;
  --ion-background-color-rgb: 22, 24, 27;

  --ion-scrollbar-color: rgb(81, 81, 81);

  /** color dark values **/
  --ion-color-step-50: #1f2023;
  --ion-color-step-100: #2b2c2e;
  --ion-color-step-150: #36373a;
  --ion-color-step-200: #424345;
  --ion-color-step-250: #4e4f51;
  --ion-color-step-300: #5a5b5d;
  --ion-color-step-350: #666668;
  --ion-color-step-400: #717274;
  --ion-color-step-450: #7d7e7f;
  --ion-color-step-500: #898a8b;
  --ion-color-step-550: #959597;
  --ion-color-step-600: #a1a1a2;
  --ion-color-step-650: #acadae;
  --ion-color-step-700: #b8b9b9;
  --ion-color-step-750: #c4c4c5;
  --ion-color-step-800: #d0d0d1;
  --ion-color-step-850: #dcdcdc;
  --ion-color-step-900: #e7e8e8;
  --ion-color-step-950: #f3f3f3;


  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;



  --ion-color-primary: #27877A;
  --ion-color-primary-rgb: 39, 135, 122;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #22776b;
  --ion-color-primary-tint: #3d9387;

  --ion-background-color: #1e1e1e;
  --ion-background-color-rgb: 23, 23, 23;

  --ion-popover-background: #222222;

  /** tertiary **/
  --ion-color-tertiary: #2b80b6;
  --ion-color-tertiary-rgb: 43, 128, 182;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2671a0;
  --ion-color-tertiary-tint: #408dbd;
  /** success **/


  --ion-color-success: #67c244;
  --ion-color-success-rgb: 103, 194, 68;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #5bab3c;
  --ion-color-success-tint: #76c857;


  /** warning **/
  --ion-color-warning: #f19c2a;
  --ion-color-warning-rgb: 241, 156, 42;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #d48925;
  --ion-color-warning-tint: #f2a63f;


  /** gradients **/
  --ion-color-gardient-primary: linear-gradient(160deg,
      rgba(220, 128, 244, 1) 0%,
      rgba(137, 128, 247, 1) 100%,
      rgba(137, 128, 247, 1) 100%);
  --ion-color-gradient-1: linear-gradient(160deg, rgba(231, 163, 115, 1) 0%, rgba(22, 95, 206, 1) 100%);
  --ion-color-gradient-2: linear-gradient(160deg, rgba(63, 62, 157, 1) 0%, rgba(147, 145, 240, 1) 100%);
  --ion-color-gradient-3: linear-gradient(160deg, rgba(167, 106, 162, 1) 0%, rgba(241, 144, 109, 1) 100%);
  --ion-color-gradient-4: linear-gradient(160deg, rgba(52, 159, 194, 1) 0%, rgba(34, 61, 173, 1) 100%);

  /** secondary **/




  --ion-color-secondary: #24262b;
  --ion-color-secondary-rgb: 36, 38, 43;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1b1d21;
  --ion-color-secondary-tint: #35373c;

  --ion-color-black: #1d1c1d;
  --ion-color-black-rgb: 29, 28, 29;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #1a191a;
  --ion-color-black-tint: #343334;


  /** medium **/
  --ion-color-medium: #7d7e8c;
  --ion-color-medium-rgb: 46, 46, 46;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #282828;
  --ion-color-medium-tint: #434343;

  /** danger **/


  --ion-color-danger: #fa3c3c;
  --ion-color-danger-rgb: 250, 60, 60;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #dc3535;
  --ion-color-danger-tint: #fb5050;

  /** dark **/
  --ion-color-dark: #16161a;
  --ion-color-dark-rgb: 22, 22, 26;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #131317;
  --ion-color-dark-tint: #2d2d31;

  /** darker **/
  --ion-color-darker: #191920;
  --ion-color-darker-rgb: 25, 25, 32;
  --ion-color-darker-contrast: #ffffff;
  --ion-color-darker-contrast-rgb: 255, 255, 255;
  --ion-color-darker-shade: #16161c;
  --ion-color-darker-tint: #303036;


  /** scoail media colors **/
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #fff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  /** notify **/
  --ion-color-notify: #e066b2;
  --ion-color-notify-rgb: 224, 102, 178;
  --ion-color-notify-contrast: #ffffff;
  --ion-color-notify-contrast-rgb: 255, 255, 255;
  --ion-color-notify-shade: #c55a9d;
  --ion-color-notify-tint: #e375ba;

  /** google **/
  --ion-color-google: #cc181e;
  --ion-color-google-rgb: 204, 24, 30;
  --ion-color-google-contrast: #fff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #b4151a;
  --ion-color-google-tint: #d12f35;

  /** info **/
  --ion-color-info: #287ccf;
  --ion-color-info-rgb: 40, 124, 207;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #236db6;
  --ion-color-info-tint: #3e89d4;

  /** pro **/
  --ion-color-pro: #ff0062;
  --ion-color-pro-rgb: 255, 0, 98;
  --ion-color-pro-contrast: #fff;
  --ion-color-pro-contrast-rgb: 255, 255, 255;
  --ion-color-pro-shade: #e00056;
  --ion-color-pro-tint: #ff1a72;

  /** twitter **/
  --ion-color-twitter: #00aced;
  --ion-color-twitter-rgb: 0, 172, 237;
  --ion-color-twitter-contrast: #fff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #0097d1;
  --ion-color-twitter-tint: #1ab4ef;

  /** steam **/
  --ion-color-steam: #171a21;
  --ion-color-steam-rgb: 23, 26, 33;
  --ion-color-steam-contrast: #ffffff;
  --ion-color-steam-contrast-rgb: 255, 255, 255;
  --ion-color-steam-shade: #14171d;
  --ion-color-steam-tint: #2e3137;

  /** striked **/
  --ion-color-striked: #1c1e23;
  --ion-color-striked-rgb: 28, 30, 35;
  --ion-color-striked-contrast: #ffffff;
  --ion-color-striked-contrast-rgb: 255, 255, 255;
  --ion-color-striked-shade: #191a1f;
  --ion-color-striked-tint: #333539;

  /** github **/
  --ion-color-github: #24292e;
  --ion-color-github-rgb: 36, 41, 46;
  --ion-color-github-contrast: #ffffff;
  --ion-color-github-contrast-rgb: 255, 255, 255;
  --ion-color-github-shade: #202428;
  --ion-color-github-tint: #3a3e43;

  /** linux **/

  --ion-color-tux: #f1ab0c;
  --ion-color-tux-rgb: 241, 171, 12;
  --ion-color-tux-contrast: #000000;
  --ion-color-tux-contrast-rgb: 0, 0, 0;
  --ion-color-tux-shade: #d4960b;
  --ion-color-tux-tint: #f2b324;

  /** Windows **/

  --ion-color-windows: #315390;
  --ion-color-windows-rgb: 49, 83, 144;
  --ion-color-windows-contrast: #ffffff;
  --ion-color-windows-contrast-rgb: 255, 255, 255;
  --ion-color-windows-shade: #2b497f;
  --ion-color-windows-tint: #46649b;

  /** Chrome **/

  --ion-color-chrome: #1e9f75;
  --ion-color-chrome-rgb: 30, 159, 117;
  --ion-color-chrome-contrast: #ffffff;
  --ion-color-chrome-contrast-rgb: 255, 255, 255;
  --ion-color-chrome-shade: #1a8c67;
  --ion-color-chrome-tint: #35a983;

  /** Apple **/

  --ion-color-apple: #b5b5b5;
  --ion-color-apple-rgb: 181, 181, 181;
  --ion-color-apple-contrast: #000000;
  --ion-color-apple-contrast-rgb: 0, 0, 0;
  --ion-color-apple-shade: #9f9f9f;
  --ion-color-apple-tint: #bcbcbc;

  /** Nintendo **/
  --ion-color-nintendo: #dd2020;
  --ion-color-nintendo-rgb: 221, 32, 32;
  --ion-color-nintendo-contrast: #ffffff;
  --ion-color-nintendo-contrast-rgb: 255, 255, 255;
  --ion-color-nintendo-shade: #c21c1c;
  --ion-color-nintendo-tint: #e03636;

  /** Ps **/
  --ion-color-ps: #0070D1;
  --ion-color-ps-rgb: 0, 112, 209;
  --ion-color-ps-contrast: #ffffff;
  --ion-color-ps-contrast-rgb: 255, 255, 255;
  --ion-color-ps-shade: #0063b8;
  --ion-color-ps-tint: #1a7ed6;


  /** xbox **/
  --ion-color-xbox: #107C10;
  --ion-color-xbox-rgb: 16, 124, 16;
  --ion-color-xbox-contrast: #ffffff;
  --ion-color-xbox-contrast-rgb: 255, 255, 255;
  --ion-color-xbox-shade: #0e6d0e;
  --ion-color-xbox-tint: #288928;


  /** header **/

  --ion-color-menu-header: #666666;
  --ion-color-menu-header-rgb: 102, 102, 102;
  --ion-color-menu-header-contrast: #ffffff;
  --ion-color-menu-header-contrast-rgb: 255, 255, 255;
  --ion-color-menu-header-shade: #5a5a5a;
  --ion-color-menu-header-tint: #757575;

  /** unselected **/
  --ion-color-unselected: #8d8e8f;
  --ion-color-unselected-rgb: 88, 88, 101;
  --ion-color-unselected-contrast: #ffffff;
  --ion-color-unselected-contrast-rgb: 255, 255, 255;
  --ion-color-unselected-shade: #4d4d59;
  --ion-color-unselected-tint: #696974;

  --ion-color-dark-primary: #0f212c;
  --ion-color-dark-primary-rgb: 15, 33, 44;
  --ion-color-dark-primary-contrast: #ffffff;
  --ion-color-dark-primary-contrast-rgb: 255, 255, 255;
  --ion-color-dark-primary-shade: #0d1d27;
  --ion-color-dark-primary-tint: #273741;

  --ion-teaser-color: #3b434a;


  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-contrast-contrast: #000000;
  --ion-color-contrast-contrast-rgb: 0, 0, 0;
  --ion-color-contrast-shade: #e0e0e0;
  --ion-color-contrast-tint: #ffffff;
}



.ion-color-contrast {
  --ion-color-base: var(--ion-color-contrast);
  --ion-color-base-rgb: var(--ion-color-contrast-rgb);
  --ion-color-contrast: var(--ion-color-contrast-contrast);
  --ion-color-contrast-rgb: var(--ion-color-contrast-contrast-rgb);
  --ion-color-shade: var(--ion-color-contrast-shade);
  --ion-color-tint: var(--ion-color-contrast-tint);
}

.ion-color-dark-primary {
  --ion-color-base: var(--ion-color-dark-primary);
  --ion-color-base-rgb: var(--ion-color-dark-primary-rgb);
  --ion-color-contrast: var(--ion-color-dark-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-primary-shade);
  --ion-color-tint: var(--ion-color-dark-primary-tint);
}


.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-darker {
  --ion-color-base: var(--ion-color-darker);
  --ion-color-base-rgb: var(--ion-color-darker-rgb);
  --ion-color-contrast: var(--ion-color-darker-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darker-contrast-rgb);
  --ion-color-shade: var(--ion-color-darker-shade);
  --ion-color-tint: var(--ion-color-darker-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-unselected {
  --ion-color-base: var(--ion-color-step-650);
  --ion-color-base-rgb: var(--ion-color-unselected-rgb);
  --ion-color-contrast: var(--ion-color-unselected-contrast);
  --ion-color-contrast-rgb: var(--ion-color-unselected-contrast-rgb);
  --ion-color-shade: var(--ion-color-unselected-shade);
  --ion-color-tint: var(--ion-color-unselected-tint);
}

.ion-color-menu-header {
  --ion-color-base: var(--ion-color-menu-header);
  --ion-color-base-rgb: var(--ion-color-menu-header-rgb);
  --ion-color-contrast: var(--ion-color-menu-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menu-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-menu-header-shade);
  --ion-color-tint: var(--ion-color-menu-header-tint);
}


.ion-color-menu {
  --ion-color-base: var(--ion-color-menu);
  --ion-color-base-rgb: var(--ion-color-menu-rgb);
  --ion-color-contrast: var(--ion-color-menu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menu-contrast-rgb);
  --ion-color-shade: var(--ion-color-menu-shade);
  --ion-color-tint: var(--ion-color-menu-tint);
}

.ion-color-notify {
  --ion-color-base: var(--ion-color-notify);
  --ion-color-base-rgb: var(--ion-color-notify-rgb);
  --ion-color-contrast: var(--ion-color-notify-contrast);
  --ion-color-contrast-rgb: var(--ion-color-notify-contrast-rgb);
  --ion-color-shade: var(--ion-color-notify-shade);
  --ion-color-tint: var(--ion-color-notify-tint);
}

.ion-color-tux {
  --ion-color-base: var(--ion-color-tux);
  --ion-color-base-rgb: var(--ion-color-tux-rgb);
  --ion-color-contrast: var(--ion-color-tux-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tux-contrast-rgb);
  --ion-color-shade: var(--ion-color-tux-shade);
  --ion-color-tint: var(--ion-color-tux-tint);
}

.ion-color-windows {
  --ion-color-base: var(--ion-color-windows);
  --ion-color-base-rgb: var(--ion-color-windows-rgb);
  --ion-color-contrast: var(--ion-color-windows-contrast);
  --ion-color-contrast-rgb: var(--ion-color-windows-contrast-rgb);
  --ion-color-shade: var(--ion-color-windows-shade);
  --ion-color-tint: var(--ion-color-windows-tint);
}

.ion-color-chrome {
  --ion-color-base: var(--ion-color-chrome);
  --ion-color-base-rgb: var(--ion-color-chrome-rgb);
  --ion-color-contrast: var(--ion-color-chrome-contrast);
  --ion-color-contrast-rgb: var(--ion-color-wichromendows-contrast-rgb);
  --ion-color-shade: var(--ion-color-chrome-shade);
  --ion-color-tint: var(--ion-color-chrome-tint);
}

.ion-color-apple {
  --ion-color-base: var(--ion-color-apple);
  --ion-color-base-rgb: var(--ion-color-apple-rgb);
  --ion-color-contrast: var(--ion-color-apple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
  --ion-color-shade: var(--ion-color-apple-shade);
  --ion-color-tint: var(--ion-color-apple-tint);
}

.dark {


  --ion-color-secondary: #24262b;
  --ion-color-secondary-rgb: 36, 38, 43;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1b1d21;
  --ion-color-secondary-tint: #35373c;


  /** menu */
  --ion-color-menu: #8d8d8d;
  --ion-color-menu-rgb: 141, 141, 141;
  --ion-color-menu-contrast: #ffffff;
  --ion-color-menu-contrast-rgb: 255, 255, 255;
  --ion-color-menu-shade: #7c7c7c;
  --ion-color-menu-tint: #c1c1c1;


  --ion-scrollbar-color: rgb(81, 81, 81);
  --ion-color-menu-header: #202731;

  /** color dark values **/
  --ion-color-step-50: #1f2023;
  --ion-color-step-100: #2b2c2e;
  --ion-color-step-150: #36373a;
  --ion-color-step-200: #424345;
  --ion-color-step-250: #4e4f51;
  --ion-color-step-300: #5a5b5d;
  --ion-color-step-350: #666668;
  --ion-color-step-400: #717274;
  --ion-color-step-450: #7d7e7f;
  --ion-color-step-500: #898a8b;
  --ion-color-step-550: #959597;
  --ion-color-step-600: #a1a1a2;
  --ion-color-step-650: #acadae;
  --ion-color-step-700: #b8b9b9;
  --ion-color-step-750: #c4c4c5;
  --ion-color-step-800: #d0d0d1;
  --ion-color-step-850: #dcdcdc;
  --ion-color-step-900: #e7e8e8;
  --ion-color-step-950: #f3f3f3;

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;



  --ion-card-background: #1c1e23;
  --ion-card-background-rgb: 28, 30, 35;
  --ion-card-background-hover: #1f2126;
  --ion-card-background-hover-rgb: 31, 33, 38;
  --ion-left-background: #16181b;
  --ion-right-background: #16181b;
  --ion-left-background-favorites: #131417;
  --ion-bg-darker: #1d2125;

  --ion-border-color: #1f2126;
  --ion-teaser-color: #3b434a;

  --ion-background-color: #16181b;
  --ion-background-color-rgb: 22, 24, 27;

  --ion-header-background: #131417;

  --ion-popover-background: #222222;
  color: var(--ion-text-color);
  --input-background: #22252a;
}

/** light theme **/
.light {

  --ion-text-color: #060422;
  --ion-text-color-rgb: 6, 4, 34;

  --ion-color-step-50: #f3f2f4;
  --ion-color-step-100: #e6e6e9;
  --ion-color-step-150: #dad9de;
  --ion-color-step-200: #cdcdd3;
  --ion-color-step-250: #c1c0c8;
  --ion-color-step-300: #b4b4bd;
  --ion-color-step-350: #a8a7b2;
  --ion-color-step-400: #9b9ba7;
  --ion-color-step-450: #8f8e9c;
  --ion-color-step-500: #838291;
  --ion-color-step-550: #767585;
  --ion-color-step-600: #6a687a;
  --ion-color-step-650: #5d5c6f;
  --ion-color-step-700: #514f64;
  --ion-color-step-750: #444359;
  --ion-color-step-800: #38364e;
  --ion-color-step-850: #2b2a43;
  --ion-color-step-900: #1f1d38;
  --ion-color-step-950: #12112d;


  --ion-border-color: #e4e7e6;


  --ion-toolbar-color: #a7a7a7;

  --ion-card-background: #F5F4F6;
  --ion-card-background-hover: #EBE9EC;



  --ion-menu-background: #f7f8f9;
  --ion-menu-bg-color: #969696;
  --ion-scrollbar-color: #a4a4a4;

  --ion-color-menu: #637381;
  --ion-color-menu-rgb: 99, 115, 129;
  --ion-color-menu-contrast: #ffffff;
  --ion-color-menu-contrast-rgb: 255, 255, 255;
  --ion-color-menu-shade: #576572;
  --ion-color-menu-tint: #73818e;

  --ion-color-light: #000;
  --ion-color-light-rgb: 0, 0, 0;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #000000;
  --ion-color-light-tint: #1a1a1a;


  color: var(--ion-text-color);

  --ion-left-background: #f6f7f9;
  --ion-right-background: #fff;
  --ion-left-background-favorites: #f6f7f9;
  --ion-header-background: #ffffff;
  --ion-bg-darker: #f8f6f6;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-popover-background: #f6f7f9;
  --ion-teaser-color: #e4e4e4;


  --ion-color-secondary: #dbdbdb;
  --ion-color-secondary-rgb: 219, 219, 219;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c1c1c1;
  --ion-color-secondary-tint: #dfdfdf;


  --input-background: #ebebeb;

  .ui-content-outer-left {
    // --ion-card-background: #e7e6eb;
    //  --ion-card-background-hover: #d1d0d7;
  }
}

ion-app.light {

  .ui-content-outer-left,
  .app-left-container,
  .modal-inner-content-menu {
    --ion-left-background: #36393e !important;
    --ion-border-color: #282b30 !important;
    --ion-left-background-favorites: #282b30 !important;

    --ion-card-background: #32353a !important;
    --ion-card-background-hover: #32353a !important;

  }

}