.landing-content {

  ion-grid,
  ion-row,
  ion-col {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  form {
    margin: 0;
    font: inherit;
    color: inherit;
    row-gap: 4px;
    display: flex;
    flex-direction: column;
  }

  ion-col {
    gap: 4px;
    display: flex;
  }

  ion-item {
    --border-width: 0;
    --padding-start: 0;
    --padding-end: 0;
    --border-width: 0;
    --padding-start: 5px;
    --padding-end: 0;
    --inner-padding-end: 0;
    --border-width: 1px;
    --border-radius: var(--app-narrow-radius);
    --border-color: rgba(var(--ion-color-light-rgb), 0.1);
  }

  /* width: 100%; */
  /* margin: 0 auto; */
  /* background: #10141e; */
  /*border-radius: 7px; */
  /* max-width: 860px; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .left-side {
    width: 100%;
    position: relative;
    background-color: var(--ion-card-background);

  }

  .right-side {
    width: 100%;
    background-color: var(--ion-background-color);
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 32px;
    margin-top: 12px;
    font-size: 32px;
  }

  h5 {
    color: var(--ion-color-step-750);
    font-size: 13px;
    margin-bottom: 6px;
  }

  .socials {
    transition: all 0.35s;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;

    ion-button {
      margin: 0;
    }
  }

  .more-button {
    background: var(--ion-color-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
  }

  .open-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .submit {
    width: 100%;
  }

  .line {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    height: 1px;
  }

  .socials.active {
    max-height: 100vh;
    padding: 0;
    padding-top: 12px;
  }

  .container-inner {
    display: flex;
    position: relative;
    width: 100%;
    /* margin: 0 auto; */
    border-radius: var(--app-narrow-radius);
    overflow: hidden;
    /* height: 100%; */
    height: 100%;
  }

  .landing-header {
    width: 100%;
    align-content: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
}

.landing-outer {
  height: 100%;
  position: relative;
  overflow: hidden;
  align-content: space-between;
}

.auth-content {
  margin: 0 auto;
  max-width: var(--max-page-size);
  height: 100% !important;
  width: 100%;
  padding: var(--ion-margin, 24px);
  display: block;
  max-width: 570px;
  margin: 0;
  margin-left: auto;
}