.dev-center-logo {
  display: flex;
  gap: 6px;
  color: var(--ion-text-color);
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;


  .logo-badge {
    background: var(--ion-card-background);
    font-size: 9px;
    padding: 4px 6px;
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
  }
}