$year-in-second : 30; // 1 Earth year = 30 sec

@function revolution($pl-year-in-days) {
  @return $pl-year-in-days * $year-in-second / 365.2563+s //Earth reference
}

@function alphaRandom() {
  @return random(1000)*.001;
}

@function stars($s, $max-area, $min-area : 0, $star-size : 0) {
  $stars : #{$min-area + random($max-area)}px #{$min-area + random($max-area)}px 0 #{$star-size}px rgba(255, 255, 255, alphaRandom());

  @for $i from 1 to $s {
    $stars: '#{$stars} , #{$min-area + random($max-area)}px #{$min-area + random($max-area)}px 0 #{$star-size}px rgba(255,255,255, #{alphaRandom()})'
  }

  @return unquote($stars);
}

$sun: 40px;
$mercury-orb : 70px;
$mercury-pl: 4px;
$venus-orb : 100px;
$venus-pl: 8px;
$earth-orb : 145px;
$earth-pl: 6px;
$mars-orb : 190px;
$mars-pl: 6px;
$jupiter-orb : 340px;
$jupiter-pl: 18px;
$saturn-orb : 440px;
$saturn-pl: 12px;
$uranus-orb : 520px;
$uranus-pl: 10px;
$neptune-orb : 630px;
$neptune-pl: 10px;
$pluto-orb : 780px;
$pluto-pl: 3px;

$asteroids-belt-orb : 300px;
$asteroids-belt-pl: 210px;

// n is number of stars required
@function multiple-box-shadow ($n, $r, $g, $b) {
  $value: '#{random(3200)}px #{random(3200)}px rgb(#{$r}, #{$g}, #{$b})';

  @for $i from 2 through $n {
    $value: '#{$value} , #{random(3200)}px #{random(3200)}px rgb(#{$r}, #{$g}, #{$b})';
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700, 255, 255, 255);
$shadows-medium: multiple-box-shadow(200, 255, 255, 255);
$shadows-big: multiple-box-shadow(100, 255, 255, 255);

.hero-stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  border-radius: 50%;
  animation: animStar 50s linear infinite;
}

.hero-stars :after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
}

.hero-stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;
}

.hero-stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
}

.hero-stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 50%;
  box-shadow: $shadows-big;
}

.hero-stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}


.hero-stars3 {
  box-shadow: $shadows-big;
}

.hero-stars {
  box-shadow: $shadows-small;
}

.hero-stars2 {
  box-shadow: $shadows-medium;
}