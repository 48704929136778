.ui-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ui-tabs-header {
    display: flex;
    position: relative;
    background: var(--ion-card-background);

    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 1px;
      left: 0;
      background-color: var(--ion-border-color);
      width: 100%;
    }

    .ui-tab-nav {
      padding: 16px 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--ion-color-step-650);
      position: relative;
      cursor: pointer;
      gap: 6px;

      ion-icon {
        font-size: 18px;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.active {
        color: var(--ion-text-color);
        background: var(--ion-card-background-hover);

      }

      &:hover {
        color: var(--ion-text-color);

      }

    }
  }

  .ui-tabs-content {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;

    .ui-tabs-tab {
      width: 100%;
      height: 100%;
      display: none;
      position: relative;
      flex-direction: column;

      gap: 0;

      &.active {
        display: flex;
      }
    }

    .ui-tabs-content-header {
      padding: 12px;
      border-bottom: 1px solid var(--ion-border-color);
    }

    .ui-tabs-content-footer {
      padding: 12px;
      border-top: 1px solid var(--ion-border-color);
    }

    .ui-tabs-content-inner {
      flex: 1;
      position: relative;
      height: 100%;

      .outer-content-abs {
        padding: 0px 12px;
      }
    }
  }
}